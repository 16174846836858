/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import {
  Form, Image,
} from 'antd';
import { toast } from 'react-toastify';
import { useNavigate, useParams } from 'react-router-dom';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import './_addRevGives.scss';
import { isNew } from 'shared/utils/global';
import {
  createRevGives, getRevGives, updateRevGives,
} from 'redux-store/revGives/revGivesActions';
import { clearData } from 'redux-store/revGives/revGivesSlice';
import InputField from 'shared/components/inputField/InputField';
import moment from 'moment';
import Button from 'shared/components/button/Button';
import { applyTrim, websitePattern, emptyFieldValidator } from 'shared/utils/validators';
// import DatePicker from 'shared/components/datePicker/DatePicker';
import StatesDropDown from 'shared/components/statesDropDown/StatesDropDown';
import UploadButton from 'shared/components/upload/Upload';
import TextAreaField from 'shared/components/textArea/TextArea';

export default function AddNewRevGives() {
  const { id } = useParams();
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [imageSrc, setImageSrc] = useState(null);
  const [goLive, setGoLive] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {
    selectedRevGives,
  } = useSelector((state) => state.revGives);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getRevGives(id));
    }
    return () => dispatch(clearData());
  }, []);

  const goBack = () => {
    form.resetFields();
    navigate('/dashboard/rev-gives/rev-gives-list');
  };

  useEffect(() => {
    if (!_.isEmpty(selectedRevGives)) {
      setImageSrc(null);
      if (id && selectedRevGives.logo?.url) {
        setImageSrc(selectedRevGives.logo?.url);
      }
      form.setFieldsValue({
        ...selectedRevGives,
        logo: selectedRevGives?.logo?.id,
        // effectiveDate: selectedRevGives?.effectiveDate ?
        // moment(new Date(selectedRevGives?.effectiveDate)).format('YYYY-MM-DD') : null,
        // termDate: selectedRevGives?.termDate ?
        // moment(new Date(selectedRevGives?.termDate)).format('YYYY-MM-DD') : null,
      });
      setRefresh(!refresh);
    }
  }, [selectedRevGives]);

  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    const payloadBody = {
      name: vals?.name,
      logo: vals?.logo,
      Website: vals?.Website,
      ein: vals?.ein,
      description: vals?.description,
      address: {
        addressLine1: vals?.address?.addressLine1,
        addressLine2: vals?.address?.addressLine2,
        city: vals?.address?.city,
        stateCode: vals?.address?.stateCode,
        postalCode: vals?.address?.postalCode,
      },
    };
    if (goLive) {
      payloadBody.status = 'published';
    }
    if (id === 'new') {
      if (goLive) {
        payloadBody.status = 'published';
      } else {
        payloadBody.status = 'draft';
      }
      dispatch(createRevGives(payloadBody)).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateRevGives({ id, params: payloadBody })).then((payload) => {
        if (_.isEmpty(payload.error)) {
          if (goLive) {
            setGoLive(false);
            toast.success('Charity is live');
            goBack();
          }
        }
      });
    }
  };

  const onGoLive = () => {
    setGoLive(true);
    form.submit();
  };

  return (
    <div className="rev-gives-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={(e) => {
          console.log(e);
          toast.error('Please fill the entire form');
        }}
      >
        <div className="rev-gives-container__outer rev-gives-college">
          <div className="rev-gives-container__wrapper">
            <h1>
              {' '}
              {isNew(id) ? 'New ' : 'Edit '}
              {' '}
              Charity
            </h1>
            <div className="section">
              <Form.Item
                name="name"
                rules={[{ required: true, message: 'This field is required' },
                ]}
              >
                <InputField
                  label="Name of the Charity *"
                  placeholder="Name of the Charity"
                  className="formfield"
                  maxLength={100}
                />
              </Form.Item>
            </div>
            <div className="section">
              <Form.Item
                name="description"
                rules={[{ required: true, message: 'This field is required' },
                ]}
              >
                <TextAreaField
                  label="Description *"
                  placeholder="Description"
                  rows={3}
                  maxLength={1500}
                  className="formfield"
                />
              </Form.Item>
            </div>
            <div className="section" style={{ flexDirection: 'column' }}>
              <label>Upload Logo *</label>
              <Form.Item
                name="logo"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <UploadButton
                  accept=".jpg, .jpeg, .png"
                  placeholder="Upload Logo"
                  onChange={(e) => setImageSrc(URL.createObjectURL(e))}
                />
              </Form.Item>
              {imageSrc && (
                <Image
                  className="promo-image"
                  width={200}
                  src={imageSrc}
                />
              )}
            </div>
            <div className="section">
              <div className="sub-col">
                <Form.Item
                  rules={[websitePattern]}
                  name="website"
                >
                  <InputField
                    label="Website"
                    placeholder="Website"
                    className="formfield"
                    maxLength={250}
                  />
                </Form.Item>
              </div>
              <div className="sub-col">
                <Form.Item
                  name="ein"
                >
                  <InputField
                    label="EIN"
                    placeholder="EIN"
                    className="formfield"
                    maxLength={250}
                  />
                </Form.Item>
              </div>
            </div>

            <div className="section no-border">
              <div className="sub-col">
                <Form.Item
                  name={['address', 'addressLine1']}
                  rules={[{ required: true, message: 'This field is required' },
                  ]}
                >
                  <InputField
                    label="Address Line 1 *"
                    placeholder="Address Line 1"
                    className="formfield"
                    maxLength={150}
                  />
                </Form.Item>
              </div>
              <div className="sub-col">
                <Form.Item
                  name={['address', 'addressLine2']}
                  rules={[{ required: true, message: 'This field is required' },
                  ]}
                >
                  <InputField
                    label="Address Line 2 *"
                    placeholder="Address Line 2"
                    className="formfield"
                    maxLength={150}
                  />
                </Form.Item>
              </div>
              <div className="sub-col">
                <Form.Item
                  name={['address', 'city']}
                  rules={[{ required: true, message: 'This field is required' },
                  ]}
                >
                  <InputField
                    label="City *"
                    placeholder="City"
                    className="formfield"
                    maxLength={150}
                  />
                </Form.Item>
              </div>
              <div className="sub-col">
                <label className="check-label-promo">
                  <div>State *</div>
                </label>
                <Form.Item
                  name={['address', 'stateCode']}
                  className="formfield"
                  rules={[{ required: true, message: 'This field is required' }]}
                >
                  <StatesDropDown
                    value={form.getFieldValue('address.stateCode')}
                    placeholder="State"
                  />

                </Form.Item>
              </div>
              <div className="sub-col">
                <Form.Item
                  name={['address', 'postalCode']}
                  rules={[{ required: true, message: 'This field is required' },
                  ]}
                >
                  <InputField
                    label="Zip *"
                    placeholder="Zip"
                    className="formfield"
                    maxLength={5}
                  />
                </Form.Item>
              </div>
            </div>

            {/* <div className="section no-border">
              <div className="sub-col promo-cal">
                <Form.Item
                  name="effectiveDate"
                  className="formfield"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Effective Date *"
                    placeholder="MM/DD/YYYY"
                    disabledDate={(d) => !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      )}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    onChange={() => form.setFieldsValue({ termDate: null })}
                    disabled={selectedRevGives?._status === 'published'}
                  />
                </Form.Item>
              </div>
              <div className="sub-col promo-cal">
                <Form.Item
                  name="termDate"
                  className="formfield"
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Termintion Date"
                    disabledDate={(d) => (form.getFieldValue('effectiveDate')
                    ? moment(d).isBefore(moment(form.getFieldValue('effectiveDate'))) : !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      ))}
                    placeholder="MM/DD/YYYY"
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                  />
                </Form.Item>
              </div>
            </div> */}
          </div>

          <div className="rev-gives-container__outer">
            <div className="action-btn-wrapper">
              <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
              {!isNew(id) && (
                <div className="updated-date-wrapper">
                  <span>Last Updated: </span>
                  <span className="orange">{moment(selectedRevGives?.updatedAt).format(' MM/DD/YYYY hh:mm A')}</span>
                </div>
              )}

              <div className="btn-promo-outer">
                <Button variant={Button.variant.filled} htmlType="submit">{isNew(id) ? 'Save' : 'Update'}</Button>
                {selectedRevGives?.status !== 'published' && <Button className="green-button" onClick={() => onGoLive()} variant={Button.variant.filled}>Go Live</Button>}
              </div>

            </div>
          </div>
        </div>
      </Form>
    </div>
  );
}
