import { createSlice } from '@reduxjs/toolkit';
import {
  getRevGives,
  listRevGives,
  createRevGives,
  updateRevGives,
} from './revGivesActions';

const INITIAL_STATE = {
  isLoading: false,
  revGives: { docs: [] },
  selectedRevGives: {},
};

export const revGivesSlice = createSlice({
  name: 'revGivesSlice',
  initialState: INITIAL_STATE,
  reducers: {
    clearData: () => INITIAL_STATE,
  },
  extraReducers: (builder) => {
    builder.addCase(listRevGives.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(listRevGives.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(listRevGives.fulfilled, (state, action) => {
      state.isLoading = false;
      state.revGives = action.payload;
    });
    builder.addCase(getRevGives.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(getRevGives.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(getRevGives.fulfilled, (state, action) => {
      state.isLoading = false;
      state.selectedRevGives = action.payload;
    });
    builder.addCase(createRevGives.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(createRevGives.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(createRevGives.fulfilled, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRevGives.pending, (state) => {
      state.isLoading = true;
    });
    builder.addCase(updateRevGives.rejected, (state) => {
      state.isLoading = false;
    });
    builder.addCase(updateRevGives.fulfilled, (state) => {
      state.isLoading = false;
    });
    return builder;
  },
});

export const {
  clearData,
} = revGivesSlice.actions;
