import React from 'react';
import HomePageTop from './HomePageTop';
import MemberHomeScreen from '../memberHomeScreen/MemberHomeScreen';

function HomeConfig(props) {
  const { type, petType } = props;
  return (
    <div>
      <HomePageTop profileType={type} petType={petType} />
      <MemberHomeScreen profileType={type} petType={petType} />
    </div>
  );
}

export default HomeConfig;
