/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { CHARITY_LOGO } from 'shared/constants/global';
import { createMedia } from 'redux-store/media/mediaActions';
import _ from 'lodash';
import {
  REVGIVES_API_URL,
} from './revGivesUrls';

export const listRevGives = createAsyncThunk(
  'revGivesSlice/listRevGives',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(REVGIVES_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getRevGives = createAsyncThunk(
  'revGivesSlice/getRevGives',
  async (id) => {
    return axios.get(REVGIVES_API_URL(id))?.then(({ data }) => data);
  },
);

export const createRevGives = createAsyncThunk(
  'revGivesSlice/createRevGives',
  async (params) => {
    let imgID = _.get(params, 'logo', null);
    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.logo, CHARITY_LOGO);
      imgID = img.doc.id;
    }

    return axios.post(
      REVGIVES_API_URL(),
      {
        ...params,
        logo: imgID,
      },
    ).then(({ data }) => {
      toast.success('Charity created successfully');
      if (params._status === 'published') {
        toast.success('Charity is live');
      }
      return data;
    });
  },
);

export const updateRevGives = createAsyncThunk(
  'revGivesSlice/updateRevGives',
  async ({ id, params }) => {
    let imgID = _.get(params, 'logo', null);
    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.logo, CHARITY_LOGO);
      imgID = img.doc.id;
    }

    return axios.patch(
      REVGIVES_API_URL(id),
      {
        ...params,
        logo: imgID,
      },
    ).then(() => {
      toast.success('Charity updated');
    });
  },
);
