/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { HOME_PAGE_ICON_API_URL } from './homePageIconsUrls';

export const getHomePageIcons = createAsyncThunk(
  'HomePageIcons/get',
  async () => {
    return axios.get(HOME_PAGE_ICON_API_URL()).then(({ data }) => data);
  },
);

export const postHomePageIcons = createAsyncThunk(
  'HomePageIcons/postHomePageIcons',
  async (params, { dispatch }) => {
    return axios.patch(
      HOME_PAGE_ICON_API_URL(params.params?.id),
      {
        ...params.params,
      },
    ).then(({ data }) => {
      toast.success('Home Page Top details updated');
      dispatch(getHomePageIcons());
      return data;
    });
  },
);
