import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { URLS } from 'routes';
import Button from 'shared/components/button/Button';
import DropDown from 'shared/components/dropdown/DropDown';
import Grid from 'shared/components/grid/Grid';
import { CharityGridColumns } from 'utils/grid/columns';
import './_revGives.scss';
import SearchBox from 'shared/components/searchbox/SearchBox';
import { listRevGives } from 'redux-store/revGives/revGivesActions';

export default function RevGives() {
  const INITIAL_PARAMS = {
    limit: 10, page: 1, depth: 0, searchText: '',
  };
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [drpValue, setDrpValue] = useState('');
  const [searchText, setSearchText] = useState('');

  const { revGives } = useSelector((state) => state.revGives);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const editClick = (id) => {
    navigate(`${URLS.REV_GIVES_URL}/${id}`);
  };

  const addNewBtnClick = () => {
    navigate(`${URLS.REV_GIVES_URL}/new`);
  };

  const ActionClickHanlder = (identifier, item) => {
    editClick(item?.id);
  };

  useEffect(() => {
    dispatch(listRevGives({ ...params }));
  }, [params]);

  const onSearch = (v) => {
    setSearchText(v);
    const payload = {
      ...INITIAL_PARAMS,
      limit: 10,
      page: 1,
    };
    if (drpValue) {
      payload['where[and][2][status][equals]'] = drpValue;
    }
    setParams({
      ...payload,
      'where[and][1][name][like]': v,
    });
  };

  const applyFilter = (val) => {
    setDrpValue(val);
    const payload = { ...INITIAL_PARAMS };
    if (searchText) {
      payload['where[and][2][name][like]'] = searchText;
    }
    if (val !== '') {
      setParams({ ...payload, 'where[and][1][status][equals]': val });
    } else {
      setParams({ ...payload });
    }
  };

  return (
    <div className="provider-list-container program-listing">
      <div className="provider-list-container__header">
        <div className="program-member-filter">
          <div>
            <span className="search-label">Search</span>
            <SearchBox placeholder="Search by Charity name" waitTime={500} onChange={onSearch} />
          </div>
        </div>
        <div>
          <span className="list-promo-filter">Status</span>
          <DropDown
            placeholder="Status"
            isOptionsObj
            className="provider-list-dropdown promo-drp"
            options={[{ label: 'ALL', value: '' },
              { label: 'IN PROGRESS', value: 'draft' },
              { label: 'LIVE', value: 'published' },
            ]}
            onChange={(e) => { applyFilter(e); }}
            value={drpValue}
          />
        </div>
        <Button variant={Button.variant.filled} htmlType="submit" onClick={addNewBtnClick}>
          <div className="btn-flex">
            + Add New Charity
          </div>
        </Button>
      </div>
      <div className="provider-list-container__body">
        <Grid
          columns={CharityGridColumns(
            { ActionClickHanlder: (identifier, item) => ActionClickHanlder(identifier, item) },
          )}
          data={revGives.docs}
          pagination={{
            total: revGives.totalDocs,
            current: params.page,
            defaultPageSize: 10,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
    </div>
  );
}
