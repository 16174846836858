/* eslint-disable no-nested-ternary */
import React, { useEffect, useState } from 'react';
import './_memberHomeScreen.scss';
import { useDispatch, useSelector } from 'react-redux';
import Grid from 'shared/components/grid/Grid';
import Button from 'shared/components/button/Button';
import SearchBox from 'shared/components/searchbox/SearchBox';
import {
  AddNewLabsGridColumns,
  AddNewProcedureGridColumns,
  AddNewProgramGridColumns,
  AddFixedGridColumns,
  AddNewGiveawayGridColumns,
  AddLinkCategoryGridColumns,
} from 'utils/grid/columns';
import { hardcodedOptions, hardcodedPetOptions } from 'shared/constants/global';
import { listProceduresSetup } from 'redux-store/proceduresSetup/proceduresSetupActions';
import { listProgram } from 'redux-store/programs/programActions';
import { listAtHomeLab } from 'redux-store/atHomeLabs/atHomeLabsActions';
import { getTermedFiltersOnField } from 'shared/utils/filters';
import DropDown from 'shared/components/dropdown/DropDown';
import { listGiveaways } from 'redux-store/giveaways/giveawayActions';
import { listCategorySetup } from 'redux-store/categorySetup/categorySetupActions';

const INITIAL_PARAMS = {
  limit: 10,
  page: 1,
  sort: '-updatedAt',
};

export default function AddNewItem({
  value, onChange, onCancel, filter, filterOptions, single = false, type,
}) {
  const [refresh, setRefresh] = useState(false);
  const [params, setParams] = useState(INITIAL_PARAMS);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tag, setTag] = useState('procedure');
  const dispatch = useDispatch();
  const { proceduresSetups } = useSelector((state) => state.proceduresSetup);
  const { programs } = useSelector((state) => state.program);
  const { atHomeLabs } = useSelector((state) => state.atHomeLabs);
  const [fixedOptions, setFixedOptions] = useState(type !== 'pet' ? hardcodedOptions : hardcodedPetOptions);
  const { giveaways } = useSelector((state) => state.giveaway);
  const { categorySetups } = useSelector((s) => s.categorySetup);

  useEffect(() => {
    if (value?.length > 0) {
      setSelectedRows(value ?? []);
    }
  }, [value]);

  useEffect(() => {
    if (tag === 'lab') {
      dispatch(
        listAtHomeLab({
          ...params,
          ...getTermedFiltersOnField('effectiveDate', 'termDate'),
        }),
      );
    } else if (tag === 'program') {
      dispatch(
        listProgram({ ...params, ...filter, 'where[status][equals]': 'live' }),
      );
    } else if (tag === 'giveaway') {
      dispatch(
        listGiveaways({ ...params, 'where[_status][equals]': 'published' }),
      );
    } else if (tag === 'procedureCategory') {
      dispatch(
        listCategorySetup({ ...params, ...getTermedFiltersOnField('procedureCategoryEffectiveDate', 'procedureCategoryTermDate') }),
      );
    } else {
      dispatch(
        listProceduresSetup({
          ...params,
          ...filter,
          'where[isProgramOnlyProcedure][not_equals]': true,
          ...getTermedFiltersOnField(
            'procedureEffectiveDate',
            'procedureTermDate',
          ),
        }),
      );
    }
  }, [params]);

  const onAdd = () => {
    onChange(selectedRows, single);
  };

  const onSelect = (row, selected) => {
    let multiple = filterOptions?.find((x) => x.value === tag)?.multiple;
    if (single) {
      multiple = false;
    }

    if (selected) {
      if (!multiple) {
        const temp = selectedRows;
        const index = temp?.findIndex((x) => x?.type === tag);
        if (index === -1) {
          temp.push({ ...row, type: tag });
        } else if (index !== -1) {
          temp.splice(index, 1);
          temp.push({ ...row, type: tag });
        }
        setSelectedRows(temp);
      } else {
        const temp = selectedRows;
        temp.push({ ...row, type: tag });
        setSelectedRows(temp);
      }
    } else if (!multiple) {
      setSelectedRows([]);
    } else {
      const temp = selectedRows;
      const index = temp?.findIndex((x) => x?.id === row?.id);
      if (index !== -1) {
        temp.splice(index, 1);
        setSelectedRows(temp);
      }
    }
    setRefresh(!refresh);
  };

  const applyFilter = (val) => {
    setTag(val);
    if (val !== '' && val !== 'hardcoded-option') {
      setParams({ ...INITIAL_PARAMS });
    }
  };

  const onSearch = (v) => {
    if (tag !== '' && tag !== 'hardcoded-option') {
      const obj = {
        [`where[${tag.toLowerCase()}Name][like]`]: v,
      };
      setParams({
        ...INITIAL_PARAMS,
        page: 1,
        ...obj,
      });
    } else if (tag === 'hardcoded-option') {
      const options = type !== 'pet' ? hardcodedOptions : hardcodedPetOptions;
      if (v !== '') {
        setFixedOptions(options?.filter((x) => x.name?.includes(v)));
      } else {
        setFixedOptions(options);
      }
    }
  };

  return (
    <div className="home-screen-item-container">
      <div className="search-section">
        {tag && (
          <div className="search-promo-text">
            <SearchBox
              placeholder={`Search by ${tag} name `}
              onChange={onSearch}
              waitTime={1300}
            />
          </div>
        )}
        <div className="drp-section">
          <span className="search-label">Item Type</span>
          <DropDown
            placeholder="Status"
            isOptionsObj
            className="rv-dropdown"
            options={filterOptions}
            onChange={(e) => {
              applyFilter(e);
            }}
            value={tag}
          />
        </div>
      </div>
      <div className="home-screen-item-container__body">
        <Grid
          columns={
            tag === 'hardcoded-option'
              ? AddFixedGridColumns(onSelect, selectedRows)
              : tag === 'lab'
                ? AddNewLabsGridColumns(onSelect, selectedRows)
                : tag === 'program'
                  ? AddNewProgramGridColumns(onSelect, selectedRows)
                  : tag === 'giveaway'
                    ? AddNewGiveawayGridColumns(onSelect, selectedRows)
                    : tag === 'procedureCategory'
                      ? AddLinkCategoryGridColumns(onSelect, selectedRows)
                      : AddNewProcedureGridColumns(onSelect, selectedRows)
          }
          data={
            tag === 'hardcoded-option'
              ? fixedOptions
              : tag === 'lab'
                ? atHomeLabs.docs
                : tag === 'program'
                  ? programs.docs
                  : tag === 'giveaway'
                    ? giveaways.docs
                    : tag === 'procedureCategory'
                      ? categorySetups.docs
                      : proceduresSetups.docs
          }
          pagination={{
            total:
              tag === 'hardcoded-option'
                ? fixedOptions?.length
                : tag === 'lab'
                  ? atHomeLabs.totalDocs
                  : tag === 'program'
                    ? programs.totalDocs
                    : tag === 'giveaway'
                      ? giveaways.totalDocs
                      : tag === 'procedureCategory'
                        ? categorySetups.totalDocs
                        : proceduresSetups.totalDocs,
            current: tag === 'hardcoded-option' ? 1 : params.page,
            defaultPageSize: INITIAL_PARAMS.limit,
            onChange: (page, pageSize) => {
              setParams({ ...params, page, limit: pageSize });
            },
          }}
        />
      </div>
      <div className="action-btn-wrapper">
        <Button onClick={onCancel} variant={Button.variant.outlined}>
          Cancel
        </Button>
        <Button
          variant={Button.variant.filled}
          onClick={onAdd}
          disabled={!selectedRows.length}
        >
          Add
        </Button>
      </div>
    </div>
  );
}
