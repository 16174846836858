/* eslint-disable no-else-return */
/* eslint-disable arrow-body-style */
/* eslint-disable import/prefer-default-export */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import axios from 'shared/axios/axios';
import { PROGRAM_IMAGE } from 'shared/constants/global';
import { createMedia } from 'redux-store/media/mediaActions';
import _ from 'lodash';
import {
  PROGRAMS_API_URL,
  PROGRAM_APPOINTMENTS_API_URL,
  PROGRAM_MEMEBERS_API_URL,
  PROGRAM_PROVIDERS_API_URL,
  FORWARD_GEO_CODE_URL,
} from './programUrls';

export const listProgram = createAsyncThunk(
  'programSlice/listProgram',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROGRAMS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const listProgramsProviders = createAsyncThunk(
  'programSlice/listProgramsProviders',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROGRAM_PROVIDERS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const listProgramsStaff = createAsyncThunk(
  'programSlice/listProgramsStaff',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROGRAM_PROVIDERS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const listProgramsAppointments = createAsyncThunk(
  'programSlice/listProgramsAppointments',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROGRAM_APPOINTMENTS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const listProgramMembers = createAsyncThunk(
  'programSlice/listProgramMembers',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(PROGRAM_MEMEBERS_API_URL(`?${query}`)).then(({ data }) => data);
  },
);

export const getPrograms = createAsyncThunk(
  'programSlice/getPrograms',
  async (id) => {
    return axios.get(PROGRAMS_API_URL(id)).then(({ data }) => data);
  },
);

export const createProgram = createAsyncThunk(
  'programSlice/createProgram',
  async (params) => {
    let imgID = _.get(params, 'programImage', null);
    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.programImage, PROGRAM_IMAGE);
      imgID = img.doc.id;
    }
    return axios.post(
      PROGRAMS_API_URL(),
      {
        ...params,
        programImage: imgID,
      },
    ).then(({ data }) => {
      return data;
    });
  },
);

export const updateProgram = createAsyncThunk(
  'programSlice/updateProceduresSetup',
  async ({ id, params }, { dispatch }) => {
    let imgID = _.get(params, 'programImage', null);
    if (imgID && typeof (imgID) === 'object') {
      const img = await createMedia(params.programImage, PROGRAM_IMAGE);
      imgID = img.doc.id;
    }
    return axios.patch(
      PROGRAMS_API_URL(id),
      {
        ...params,
        programImage: imgID,
      },
    ).then(() => {
      return dispatch(getPrograms(id));
    });
  },
);

export const updateStatusProgramsSetup = createAsyncThunk(
  'programSlice/updateStatusProgramsSetup',
  async (data) => {
    return axios.patch(PROGRAMS_API_URL(data?.id), { id: data?.id, '_status': data?.status, effectiveDate: data?.effectiveDate })
      .then(() => {
        toast.success('Program is live');
      });
  },
);

export const cancelProgramsSetup = createAsyncThunk(
  'programSlice/cancelProgramsSetup',
  async (data, { dispatch }) => {
    return axios.patch(PROGRAMS_API_URL(`${data?.id}/update-status`), { 'status': data?.status })
      .then(() => {
        if (data?.status === 'live') {
          toast.success('Program is live');
        } else {
          toast.success(`Program has been ${data?.status}`);
        }
        dispatch(listProgram({ depth: 0 }));
      });
  },
);

export const createProgramAppointments = createAsyncThunk(
  'programSlice/createProgramAppointments',
  async (params) => {
    let imgID = _.get(params, 'programAppointmentImage', null);
    if (imgID && typeof (imgID) === 'object' && !imgID?.id) {
      const img = await createMedia(params.programAppointmentImage, PROGRAM_IMAGE);
      imgID = img.doc.id;
    } else {
      imgID = _.get(params, 'programAppointmentImage.id', undefined);
    }
    const dataPayload = params;
    if (!dataPayload.live && dataPayload.isDependentOnOtherAppointments === true) {
      dataPayload.isDependentOnOtherAppointments = false;
      if (dataPayload.prerequisiteAppointments?.length > 0) {
        delete dataPayload.prerequisiteAppointments;
      }
    }
    return axios.post(
      PROGRAM_APPOINTMENTS_API_URL(),
      {
        ...dataPayload,
        programAppointmentImage: imgID,
      },
    ).then(({ data }) => {
      return data;
    });
  },
);

export const updateProgramAppointments = createAsyncThunk(
  'programSlice/updateProgramAppointments',
  async ({ id, params }) => {
    let imgID = _.get(params, 'programAppointmentImage', null);
    if (imgID && typeof (imgID) === 'object' && !imgID?.id) {
      const img = await createMedia(params.programAppointmentImage, PROGRAM_IMAGE);
      imgID = img.doc.id;
    } else {
      imgID = _.get(params, 'programAppointmentImage.id', undefined);
    }
    const dataPayload = params;
    if (!dataPayload.live && dataPayload.isDependentOnOtherAppointments === true) {
      dataPayload.isDependentOnOtherAppointments = false;
      if (dataPayload.prerequisiteAppointments?.length > 0) {
        delete dataPayload.prerequisiteAppointments;
      }
    }
    return axios.patch(
      PROGRAM_APPOINTMENTS_API_URL(id),
      { ...dataPayload, programAppointmentImage: imgID },
    ).then(({ data }) => {
      return data;
    });
  },
);

export const updateProgramLinkedAppointments = createAsyncThunk(
  'programSlice/updateProgramLinkedAppointments',
  async ({ id, params }) => {
    return axios.patch(
      PROGRAM_APPOINTMENTS_API_URL(id),
      { ...params },
    ).then(({ data }) => {
      return data;
    });
  },
);

export const bulkUpdateProgramAppointments = createAsyncThunk(
  'programSlice/bulkUpdateProgramAppointments',
  async ({ id, params }) => {
    const query = new URLSearchParams(id).toString();
    return axios.patch(
      PROGRAM_APPOINTMENTS_API_URL(query),
      { ...params },
    ).then(() => {
    });
  },
);

export const createProgramProvider = createAsyncThunk(
  'programSlice/createProgramProvider',
  async (params) => {
    return axios.post(
      PROGRAM_PROVIDERS_API_URL(),
      { ...params },
    ).then(({ data }) => {
      return data;
    });
  },
);

export const updateProgramProvider = createAsyncThunk(
  'programSlice/updateProgramProvider',
  async ({ id, params }) => {
    return axios.patch(
      PROGRAM_PROVIDERS_API_URL(id),
      { ...params },
    ).then(() => {
    });
  },
);

export const bulkUpdateProgramProvider = createAsyncThunk(
  'programSlice/bulkUpdateProgramProvider',
  async ({ id, params }) => {
    const query = new URLSearchParams(id).toString();
    return axios.patch(
      PROGRAM_PROVIDERS_API_URL(query),
      { params },
    ).then(() => {
    });
  },
);

export const getProgramMember = createAsyncThunk(
  'programSlice/getProgramMember',
  async (id) => {
    return axios.get(PROGRAM_MEMEBERS_API_URL(id)).then(({ data }) => data);
  },
);

export const createProgramMember = createAsyncThunk(
  'programSlice/createProgramMember',
  async (params) => {
    return axios.post(
      PROGRAM_MEMEBERS_API_URL(),
      { ...params },
    ).then(({ data }) => {
      toast.success('Member invited successfully');
      return data;
    });
  },
);

export const updateProgramMemebr = createAsyncThunk(
  'programSlice/updateProgramMemebr',
  async ({ id, params }) => {
    return axios.patch(
      PROGRAM_MEMEBERS_API_URL(id),
      { ...params },
    ).then(() => {
      toast.success('Member details updated');
    });
  },
);

export const searchUsAddress = createAsyncThunk(
  'programSlice/searchUsAddress',
  async (filters) => {
    const query = new URLSearchParams(filters).toString();
    return axios.get(FORWARD_GEO_CODE_URL(`&${query}`)).then(({ data }) => data);
  },
);

export const getProgramPrice = createAsyncThunk(
  'programSlice/getProgramPrice',
  async (params) => {
    return axios.post(
      PROGRAMS_API_URL('pricing-snapshot'),
      { ...params },
    ).then(({ data }) => {
      return data;
    });
  },
);
