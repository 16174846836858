/* eslint-disable jsx-a11y/label-has-associated-control */
/* eslint-disable jsx-a11y/label-has-for */
import React, { useState, useEffect } from 'react';
import {
  Form, Image, Select, Popconfirm,
  Space, AutoComplete,
  Spin,
} from 'antd';
import _ from 'lodash';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';
import {
  cancelProgramsSetup,
  createProgram, createProgramAppointments,
  createProgramProvider,
  getProgramPrice,
  getPrograms,
  listProgramsProviders, searchUsAddress, updateProgram,
  updateProgramAppointments,
  updateProgramLinkedAppointments,
  updateProgramProvider,
} from 'redux-store/programs/programActions';
import { clearData } from 'redux-store/programs/programSlice';
import { toast } from 'react-toastify';
import {
  allowOnlyANSI, allowOnlyNumbers, applyTrim, emptyFieldValidator,
  failSafeEmptyNonZeroFieldValidator, blackListedChars,
  allowDecimalNumbers,
} from 'shared/utils/validators';
import RadioGroup from 'shared/components/radioGroup/RadioGroup';
import InputField from 'shared/components/inputField/InputField';
import TextAreaField from 'shared/components/textArea/TextArea';
import UploadButton from 'shared/components/upload/Upload';
import DatePicker from 'shared/components/datePicker/DatePicker';
import RadioButton from 'shared/components/radiobutton/RadioButton';
import CheckBox from 'shared/components/checkbox/CheckBox';
import moment from 'moment';
import './_addNewProgram.scss';
import {
  PlusIcon,
  GreyTrashIcon,
  WarnigAlertlIcon,
} from 'utils/grid/columns';
import Button from 'shared/components/button/Button';
import { isNew } from 'shared/utils/global';
import { getChecklists } from 'shared/utils/checklist';
import DropDown from 'shared/components/dropdown/DropDown';

import MultiCheckboxSelect from 'shared/components/multiCheckboxSelect/MultiCheckboxSelect';
import { DELIVERY_METHODS_APPOINTMENT } from 'shared/constants/global';
import { getNonDeletedRecordsFilter } from 'shared/utils/filters';
import { listDeliveryMethod } from 'redux-store/deliveryMethod/deliveryMethodActions';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import { listAllProviders } from 'redux-store/myProviders/myProvidersActions';
import { listProviderType } from 'redux-store/providerType/providerTypeActions';
import { getProgramCancellationPolicyLive } from 'redux-store/programCancellationPolicy/programCancellationPolicyActions';
import { RichTextEditor } from 'shared/components/richTextEditor/RichTextEditor';
import LinkedProcedure from '../procedureManagement/components/addMedicationSets/LinkedProcedure';
import { Appointments } from './Appointments';
import PreviewProgram from './ProgramPreview';
import ProgramProviders from './ProgramProviders';

export default function AddNewProgram() {
  const { id } = useParams();
  const [searchParams] = useSearchParams();
  const [, forceUpdate] = useState(0);
  const [refresh, setRefresh] = useState(false);
  const [invalidPricing, setInvalidPricing] = useState(false);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const [imageSrc, setImageSrc] = useState(null);
  const [searchAddressRequest, setSearchAddressRequest] = useState(null);
  const [error, setError] = useState(false);
  const [goLive, setGoLive] = useState(false);
  const [dialogOpen, setDialogOpen] = useState({
    title: '', isOpen: false, isPreview: false, isStaff: false,
  });
  const [searchByProviderNamePromise, setSearchByProviderNamePromise] = useState(null);
  const [providerWarning, setProviderWarning] = useState([]);
  const months = new Array(12).fill(null).map((_a, i) => ({ value: i + 1, label: i + 1 }));
  const [delStaff, setDelStaff] = useState([]);
  const [delApmt, setDelApmt] = useState([]);

  const {
    selectedProgram,
    programsProviders,
    address,
    isLoading,
    programCalculations,
    isCalcLoading,
  } = useSelector((state) => state.program);
  const myId = useSelector((state) => state?.auth?.user?.id);
  const { uniqueStates } = useSelector((state) => state.userManagementSetups);
  const { deliveryMethods = { docs: [] } } = useSelector((s) => s.deliveryMethod);
  const { providerTypes } = useSelector((state) => state.providerType);
  const { liveProgramCancellationPolicy } = useSelector((state) => state.programCancellationPolicy);

  useEffect(() => {
    if (id !== 'new') {
      dispatch(getPrograms(id));
      const payload = {
        limit: 0,
        'where[and][0][program][equals]': id,
        'where[and][1][deletedInfoField.isDeleted][not_equals]': true,
      };
      dispatch(listProgramsProviders({ ...payload, depth: 1 }));
    } else {
      dispatch(getProgramCancellationPolicyLive());
    }
    return () => dispatch(clearData());
  }, [id]);
  useEffect(() => {
    if (deliveryMethods === undefined) {
      dispatch(listDeliveryMethod({
        limit: 0,
        page: 1,
        ...getNonDeletedRecordsFilter(),
      }));
      dispatch(listProviderType({ limit: 0 }));
    }
  }, []);

  const getIsStateAddress = () => {
    if (selectedProgram?.isRegionDependent === true) {
      return ((selectedProgram?.address === null
      || selectedProgram?.address === undefined
      || _.isEmpty(selectedProgram?.address)) || selectedProgram?.radiusForAddressInMiles === null);
    }
    return null;
  };

  useEffect(() => {
    if (!_.isEmpty(selectedProgram)) {
      setImageSrc(null);
      if (id && selectedProgram.programImage?.url) {
        setImageSrc(selectedProgram.programImage?.url);
      }
      form.setFieldsValue({
        ...selectedProgram,
        procedureCategory: [{ ...selectedProgram?.procedureCategory }],
        appointments: (selectedProgram?.programStructureType === 'structured' || selectedProgram?.programStructureType === 'both') ? selectedProgram?.programAppointments?.map((a, i) => ({
          ...a,
          appointmentTypeInfo: a.appointmentType === 'informational',
          appointmentTypeAssessment: a.appointmentType === 'assessment',
          prerequisiteAppointments: a.isDependentOnOtherAppointments === true ? [i] : [],
          deliveryMethod: a.deliveryMethod?.id ? [a.deliveryMethod?.id] : a.deliveryMethod,
          order: i + 1,
        })) : [],
        programImage: selectedProgram?.programImage?.id,
        effectiveDate: selectedProgram?.effectiveDate && !searchParams.get('clone') ? moment(new Date(selectedProgram?.effectiveDate)).format('YYYY-MM-DD') : null,
        isStateAddress: getIsStateAddress(),
      });
      setRefresh(!refresh);
    }
  }, [selectedProgram]);

  useEffect(() => {
    if (liveProgramCancellationPolicy?.policyText) {
      form.setFields([{
        name: 'termsAndConditions',
        value: liveProgramCancellationPolicy?.policyText,
      }]);
    }
  }, [liveProgramCancellationPolicy]);

  useEffect(() => {
    if (programsProviders?.docs?.length > 0) {
      form.setFields([{
        name: 'staff',
        value: programsProviders?.docs,
      }]);
    }
  }, [programsProviders]);

  const isLive = () => (selectedProgram?.status === 'live' && !searchParams.get('clone'));

  const calculatePrice = () => {
    const vals = form.getFieldsValue();
    if (vals?.appointments?.length > 0 || vals?.unstructuredProcedures?.length > 0) {
      const totalAssessmentFee = vals?.appointments
        ?.map((a) => (a?.assessmentFee || 0))
        ?.reduce((s, a) => parseFloat(s, 10) + parseFloat(a, 10), 0) || 0;
      const unstructuredProcedures = (vals?.unstructuredProcedures
        ?.filter((a) => a !== undefined) || [])
        ?.map((s) => s?.id);

      const appointments = vals?.appointments
        ?.filter((a) => a !== undefined)
        ?.map((a) => ({
          deliveryMethod: (a?.deliveryMethod
        && a?.deliveryMethod?.length > 0 && a.deliveryMethod[0]) || null,
          procedures: (a?.procedures
      && a?.procedures?.length > 0
      && a?.procedures?.filter((p) => p !== undefined)
        ?.map((s) => s?.id)) || [],
        }))?.filter((x) => x !== undefined)?.filter((x) => x?.deliveryMethod !== null);

      const calcPayload = {
        createdByType: selectedProgram.createdByType || 'revDoc',
        programStructureType: vals?.programStructureType || selectedProgram.programStructureType,
        programDurationInMonths: vals.programDurationInMonths || 0,
        assessmentFee: totalAssessmentFee,
        cancellationFee: parseFloat(vals.cancellationFee || 0),
        programPricing: {
          houseCallPrice: parseFloat(vals?.programPricing?.houseCallPrice || 0),
          luxeMobileClinicsPrice: parseFloat(vals?.programPricing?.luxeMobileClinicsPrice || 0),
          telemedicinePrice: parseFloat(vals?.programPricing?.telemedicinePrice || 0),
          facilityPrice: parseFloat(vals?.programPricing?.facilityPrice || 0),
          messagingPrice: parseFloat(vals?.programPricing?.messagingPrice || 0),
          houseCallQuantity: Number(vals?.programPricing?.houseCallQuantity || 0),
          luxeMobileClinicsQuantity: Number(vals?.programPricing?.luxeMobileClinicsQuantity || 0),
          telemedicineQuantity: Number(vals?.programPricing?.telemedicineQuantity || 0),
          facilityQuantity: Number(vals?.programPricing?.facilityQuantity || 0),
          messagingQuantity: Number(vals?.programPricing?.messagingQuantity || 0),
          ingredientInflationAmount:
          parseFloat(vals?.programPricing?.ingredientInflationAmount || 0),
          programMarkupPercentage: parseFloat(vals?.programPricing?.programMarkupPercentage || 0),
          rev1MarkupPercentage: parseFloat(vals?.programPricing?.rev1MarkupPercentage || 0),
        },
        unstructuredProcedures,
        programAppointments: appointments || [],
      };
      if (searchByProviderNamePromise && searchByProviderNamePromise?.abort) {
        searchByProviderNamePromise?.abort();
      }
      const promise = dispatch((getProgramPrice(calcPayload)));
      setSearchByProviderNamePromise(promise);
      setRefresh(!refresh);
    }
  };

  useEffect(() => {
    if ((selectedProgram?.status !== 'live' || searchParams.get('clone')) && (form.getFieldValue('programStructureType') !== undefined)) calculatePrice();
  }, [form.getFieldValue('appointments'),
    form.getFieldValue('unstructuredProcedures'),
    form.getFieldValue('programStructureType'),
    form.getFieldValue('labs'),
    form.getFieldValue(['programPricing', 'rev1MarkupPercentage']),
    form.getFieldValue(['programPricing', 'programMarkupPercentage']),
    form.getFieldValue(['programPricing', 'ingredientInflationAmount']),
    form.getFieldValue(['programPricing', 'houseCallQuantity']),
    form.getFieldValue(['programPricing', 'houseCallPrice']),
    form.getFieldValue(['programPricing', 'luxeMobileClinicsQuantity']),
    form.getFieldValue(['programPricing', 'luxeMobileClinicsPrice']),
    form.getFieldValue(['programPricing', 'telemedicineQuantity']),
    form.getFieldValue(['programPricing', 'telemedicinePrice']),
    form.getFieldValue(['programPricing', 'facilityQuantity']),
    form.getFieldValue(['programPricing', 'facilityPrice']),
    form.getFieldValue(['programPricing', 'messagingQuantity']),
    form.getFieldValue(['programPricing', 'messagingPrice']),
    form.getFieldValue('programDurationInMonths'), selectedProgram, id, deliveryMethods]);

  useEffect(() => {
    form.setFields([{ name: ['programPricing', 'pharmaceuticalAndMaterialPrice'], value: programCalculations?.programPricing?.pharmaceuticalAndMaterialPrice },
      { name: 'assessmentFee', value: programCalculations?.assessmentFee },
      { name: ['programPricing', 'deliveryFee'], value: programCalculations?.programPricing?.deliveryFee },
      { name: ['programPricing', 'totalProgramPrice'], value: programCalculations?.programPricing?.totalProgramPrice },
      { name: ['programPricing', 'totalAtHomeLabsPrice'], value: programCalculations?.programPricing?.totalAtHomeLabsPrice },
      { name: ['programPricing', 'programPricePerMonth'], value: programCalculations?.programPricing?.programPricePerMonth },
      { name: 'finalBasicMembershipPrice', value: programCalculations?.finalBasicMembershipPrice },
      { name: 'finalPlusMembershipPrice', value: programCalculations?.finalPlusMembershipPrice }]);
    setRefresh(!refresh);
  }, [programCalculations]);

  const goBack = () => {
    form.resetFields();
    navigate('/dashboard/program/programs');
  };

  const updateAppointmentLink = (appointmentsData) => {
    const tempAppointments = form?.getFieldValue('appointments');
    const linkedData = tempAppointments?.map((a, i) => {
      if (a.isDependentOnOtherAppointments === true && i > 0 && appointmentsData?.length > i) {
        const appointmentId = appointmentsData[i - 1]?.id;
        if (appointmentId) {
          return ({
            ...appointmentsData[i],
            isDependentOnOtherAppointments: true,
            prerequisiteAppointments: [appointmentId],
          });
        }
      }
      return null;
    })?.filter((a) => a !== null);

    if (linkedData?.length > 0 && !isLive()) {
      let errorAppointment = false;
      const appoinmentDispatches = linkedData?.map((a) => dispatch(updateProgramLinkedAppointments(
        { id: a.id, params: a },
      ))
        .then((appointmentPayload) => {
          if (!errorAppointment && _.isEmpty(appointmentPayload?.payload?.error)) {
            a.id = appointmentPayload?.payload?.doc?.id;
          } else {
            errorAppointment = true;
          }
        }));

      Promise.all(appoinmentDispatches);
    }
  };

  const updateProgramStatus = (prgramId) => {
  // validation check for opt-in or vol-opt-in when go live
    if (form.getFieldValue('isAutoOptIn') === true || form.getFieldValue('isVoluntarilyOptIn') === true) {
      let valid = true;
      let proceduresItems = [];
      if (form.getFieldValue('programStructureType') === 'unstructured') {
        proceduresItems = form.getFieldValue('unstructuredProcedures') || [];
      } else if (form.getFieldValue('programStructureType') === 'structured') {
        proceduresItems = form.getFieldValue('appointments')?.map((a) => (a.procedures))?.flatMap((a) => (a)) || [];
      } else if (form.getFieldValue('programStructureType') === 'both') {
        const unSturcturedProcedures = form.getFieldValue('unstructuredProcedures') || [];
        const sturcturedProcedures = form.getFieldValue('appointments')?.map((a) => (a.procedures))?.flatMap((a) => (a)) || [];
        proceduresItems = unSturcturedProcedures.concat(sturcturedProcedures);
      }

      let procedureFilter = proceduresItems?.reduce((acc, o, i) => {
        acc[`where[and][0][defaultProcedures.procedureId][in][${i}]`] = o.id;
        return acc;
      }, {});

      if (form.getFieldValue('isRegionDependent') === true) {
        let stateCodes = [];
        if (form.getFieldValue('isStateAddress') === true) {
          stateCodes = form.getFieldValue('stateCodes');
        } else if (form.getFieldValue('isStateAddress') === false) {
          stateCodes = [form.getFieldValue('address')?.stateCode]?.filter((x) => x !== undefined && x !== null);
        }
        const stateFilter = stateCodes?.reduce((acc, o, i) => {
          acc[`where[and][1][or][${i}][licenses.${o}.licenseExpirationDate][greater_than]`] = new Date().toISOString();
          return acc;
        }, {});
        procedureFilter = { ...procedureFilter, ...stateFilter };
      }

      if (!_.isEmpty(procedureFilter)) {
        const request = dispatch(listAllProviders({
          ...procedureFilter,
          depth: 0,
          limit: 1,
        })).then((payload) => {
          if (!(_.isEmpty(payload.error) && payload?.payload?.totalDocs > 0)) {
            valid = false;
          }
        });
        Promise.all([request]).then(() => {
          if (valid) {
            dispatch(cancelProgramsSetup({ id: prgramId, status: 'published' })).then((updatepayload) => {
              if (_.isEmpty(updatepayload.error)) {
                setGoLive(false);
                goBack();
              }
            });
          } else {
            toast.warning('Providers are not available for this program configuration');
            if ((id === 'new') || searchParams.get('clone')) {
              setGoLive(false);
              goBack();
            }
          }
        });
      }
    } else {
      dispatch(cancelProgramsSetup({ id: prgramId, status: 'published' })).then((updatepayload) => {
        if (_.isEmpty(updatepayload.error)) {
          setGoLive(false);
          goBack();
        }
      });
    }
  };

  const completeSaveProgram = (vals, prgramId, updatePayload) => {
    if (vals?.appointments?.length > 0 && !isLive()) {
      updatePayload.programAppointments = vals?.appointments?.map((a) => (a.id));
      updateAppointmentLink(vals?.appointments);
    }

    if ((id === 'new') || searchParams.get('clone')) {
      if (goLive) {
        toast.success((id === 'new' || searchParams.get('clone')) ? 'Program has been created' : 'Program has been updated');
        updateProgramStatus(prgramId);
      } else {
        toast.success('Program has been created');
        goBack();
      }
    } else {
      dispatch(updateProgram({ id: prgramId, params: updatePayload })).then((updatepayload) => {
        if (_.isEmpty(updatepayload.error)) {
          if (goLive) {
            toast.success((id === 'new' || searchParams.get('clone')) ? 'Program has been created' : 'Program has been updated');
            updateProgramStatus(prgramId);
          } else {
            setGoLive(false);
            toast.success('Program has been updated');
            const payload = {
              limit: 0,
              'where[and][0][program][equals]': prgramId,
              'where[and][1][deletedInfoField.isDeleted][not_equals]': true,
            };
            dispatch(listProgramsProviders({ ...payload, depth: 1 }));
          }
        }
      });
    }
  };

  const pricingValidation = (check) => {
    if (check) {
      let disabledDeliveryMethods = ['text-messaging'];
      if (form.getFieldValue('isOnlineOnly') === true) {
        disabledDeliveryMethods = ['facility', 'van', 'house-call', 'telemedicine'];
      }
      let counter = 0;
      deliveryMethods?.docs?.forEach((o) => {
        if (disabledDeliveryMethods?.includes(o.internalIdentifier) === false && ((form.getFieldValue('programStructureType') === 'structured' && parseInt(form.getFieldValue(['programPricing', DELIVERY_METHODS_APPOINTMENT[o.internalIdentifier].price]), 2) === 0)
        || (form.getFieldValue('programStructureType') === 'unstructured' && (parseInt(form.getFieldValue(['programPricing', DELIVERY_METHODS_APPOINTMENT[o.internalIdentifier].price]), 2) === 0 || parseInt(form.getFieldValue(['programPricing', DELIVERY_METHODS_APPOINTMENT[o.internalIdentifier].value]), 2) === 0)))) {
          counter += 1;
        }
      });
      const targetCounter = (form.getFieldValue('programStructureType') === 'unstructured' || (form.getFieldValue('isOnlineOnly') === false && form.getFieldValue('programStructureType') === 'structured')) ? 4 : 1;
      if (counter === targetCounter) {
        setInvalidPricing(true);
        return false;
      }
      setInvalidPricing(false);
    }
    return true;
  };

  const validStaff = () => {
    let valid = true;
    let inValidProviders = [];
    let proceduresItems = [];
    if (form.getFieldValue('programStructureType') === 'unstructured') {
      proceduresItems = form.getFieldValue('unstructuredProcedures') || [];
    } else if (form.getFieldValue('programStructureType') === 'structured') {
      proceduresItems = form.getFieldValue('appointments')?.map((a) => (a.procedures))?.flatMap((a) => (a)) || [];
    } else if (form.getFieldValue('programStructureType') === 'both') {
      const unSturcturedProcedures = form.getFieldValue('unstructuredProcedures') || [];
      const sturcturedProcedures = form.getFieldValue('appointments')?.map((a) => (a.procedures))?.flatMap((a) => (a)) || [];
      proceduresItems = unSturcturedProcedures.concat(sturcturedProcedures);
    }

    if (form.getFieldValue('isSelectStaff') === true
    && form.getFieldValue('staff')?.length > 0) {
      if (proceduresItems?.length > 0) {
        form.getFieldValue('staff')?.forEach((s) => {
          const defaultProcedures = s.defaultProcedures?.map((x) => x.id);
          if (defaultProcedures && defaultProcedures?.length > 0) {
            proceduresItems?.forEach((p) => {
              if (defaultProcedures?.includes(p.id) === -1) {
                const index = inValidProviders?.findIndex((x) => x === s.id);
                if (index && index === -1) {
                  inValidProviders.push(s.id);
                }
                valid = false;
              }
            });
          } else {
            const index = inValidProviders?.findIndex((x) => x === s.id);
            if (index && index === -1) {
              inValidProviders.push(s.id);
            }
            valid = false;
          }

          if (form.getFieldValue('isRegionDependent') === true) {
            let stateCodes = [];
            if (form.getFieldValue('isStateAddress') === true) {
              stateCodes = form.getFieldValue('stateCodes');
            } else if (form.getFieldValue('isStateAddress') === false) {
              stateCodes = [form.getFieldValue('address')?.stateCode]?.filter((x) => x !== undefined && x !== null);
            }
            if (stateCodes?.length > 0) {
              let isValid = false;
              const inValidStateProviders = [];
              if (s.licensedStateCodes === undefined
                || (s.licensedStateCodes && s.licensedStateCodes?.length === 0)) {
                const index = inValidProviders?.findIndex((x) => x === s.id);
                if (index && index === -1) {
                  inValidStateProviders.push(s.id);
                }
                valid = false;
              } else {
                stateCodes?.forEach((state) => {
                  if (!isValid) {
                    if (s.licensedStateCodes?.includes(state) === -1) {
                      const index = inValidProviders?.findIndex((x) => x === s.id);
                      if (index && index === -1) {
                        inValidStateProviders.push(s.id);
                      }
                      valid = false;
                    } else {
                      const index = inValidProviders?.findIndex((x) => x === s.id);
                      if (index && index !== -1) {
                        inValidStateProviders.splice(index, 1);
                      }
                      isValid = true;
                    }
                  }
                });
              }

              inValidProviders = inValidStateProviders.concat(inValidProviders);
            }
          }
        });
        setProviderWarning(inValidProviders);
      }
    }

    return valid;
  };

  const getAppointmentType = (i, a) => {
    let type = 'program';
    if (i === true) {
      type = 'informational';
    } else if (a === true) {
      type = 'assessment';
    }
    return type;
  };

  const handleSubmit = async (vals) => {
    setError(false);
    if (((form.getFieldValue('revBasic') === true
|| form.getFieldValue('revPrivate') === true
|| form.getFieldValue('revPlus') === true) && (form.getFieldValue('isAutoOptIn') === true
|| form.getFieldValue('isSelectStaff') === true
|| form.getFieldValue('isVoluntarilyOptIn') === true)) && validStaff() && pricingValidation(false)) {
      vals = applyTrim(vals);
      vals.ownerId = myId;
      vals.effectiveDate = vals?.effectiveDate ? moment(vals?.effectiveDate).format('YYYY-MM-DD') : null;
      vals.createdByType = vals?.createdByType || 'revDoc';
      if (vals[`${vals.durationTerm}Value`]) {
        vals.durationTerm = Number(vals[`${vals.durationTerm}Value`]);
        delete vals[`${vals.durationTerm}Value`];
      }
      vals.isSpecificMemberOnly = false;
      vals.maxMembers = vals.memberLimitType === 'specificNumber' ? vals.maxMembers : null;
      vals.revBasic = vals.revBasic || false;
      vals.revPlus = vals.revPlus || false;
      vals.revPrivate = vals.revPrivate || false;
      vals.isAutoOptIn = vals.isAutoOptIn || false;
      vals.isSelectStaff = vals.isSelectStaff || false;
      vals.isVoluntarilyOptIn = vals.isVoluntarilyOptIn || false;
      if (vals.applicableMemberTypes?.includes('pet') === false) {
        vals.petTypes = [];
      }
      let structuredProcedures = [];
      let unstructuredProcedures = [];
      if (vals?.appointments?.length > 0) {
        structuredProcedures = vals?.appointments?.map((a) => (a.procedures)?.map((sp) => (sp.id)))
          ?.flatMap((a) => (a));
        structuredProcedures = structuredProcedures
          ?.filter((obj, index) => index === structuredProcedures?.findIndex((o) => obj === o));
        // labs
        vals.labs = vals?.appointments?.map((a) => (a.labs)?.map((sp) => (sp.id)))
          ?.flatMap((a) => (a));
        vals.labs = vals.labs
          ?.filter((obj, index) => index === vals.labs
            ?.findIndex((o) => obj === o))?.filter((x) => x !== null && x !== undefined);

        vals.appointments = vals?.appointments?.map((a, index) => ({
          ...a,
          sequenceNumber: index + 1,
          appointmentName: a.appointmentName || '',
          assessmentFee: a.assessmentFee || 0,
          appointmentType: getAppointmentType(a?.appointmentTypeInfo, a?.appointmentTypeAssessment),
          procedures: a.procedures?.map((sp) => (sp.id)),
          labs: a.labs?.map((sp) => (sp.id)),
          deliveryMethod: a.deliveryMethod?.length > 0 ? a.deliveryMethod[0] : null,
          status: 'inReview',
        }));
      }
      if (vals.unstructuredProcedures?.length > 0) {
        unstructuredProcedures = vals?.unstructuredProcedures?.map((x) => (x.id));
        vals.unstructuredProcedures = unstructuredProcedures;
        vals.labs = vals?.labs?.map((x) => (x.id))?.filter((x) => x !== null && x !== undefined);
      }
      vals.procedures = unstructuredProcedures.concat(structuredProcedures);
      vals.procedureCategory = vals?.procedureCategory?.length > 0
        ? vals?.procedureCategory?.map((x) => (x.id))[0] : null;
      vals.programPricing = {
        ...vals.programPricing,
        houseCallPrice: parseFloat(vals?.programPricing?.houseCallPrice || 0),
        luxeMobileClinicsPrice: parseFloat(vals?.programPricing?.luxeMobileClinicsPrice || 0),
        telemedicinePrice: parseFloat(vals?.programPricing?.telemedicinePrice || 0),
        facilityPrice: parseFloat(vals?.programPricing?.facilityPrice || 0),
        messagingPrice: parseFloat(vals?.programPricing?.messagingPrice || 0),
        houseCallQuantity: Number(vals?.programPricing?.houseCallQuantity || 0),
        luxeMobileClinicsQuantity: Number(vals?.programPricing?.luxeMobileClinicsQuantity || 0),
        telemedicineQuantity: Number(vals?.programPricing?.telemedicineQuantity || 0),
        facilityQuantity: Number(vals?.programPricing?.facilityQuantity || 0),
        messagingQuantity: Number(vals?.programPricing?.messagingQuantity || 0),
        ingredientInflationAmount: parseFloat(vals?.programPricing?.ingredientInflationAmount || 0),
        programMarkupPercentage: parseFloat(vals?.programPricing?.programMarkupPercentage || 0),
        rev1MarkupPercentage: parseFloat(vals?.programPricing?.rev1MarkupPercentage || 0),
      };

      vals.finalPrivateMembershipPrice = vals.finalPlusMembershipPrice;
      vals.programPricing.programPricePerYear = vals.programPricing.totalProgramPrice || 0;

      vals.oneTimeFinalBasicMembershipPrice = parseFloat(vals.finalBasicMembershipPrice
        * vals.programDurationInMonths).toFixed(2) || 0;
      vals.oneTimeFinalPlusMembershipPrice = parseFloat(vals.finalPlusMembershipPrice
        * vals.programDurationInMonths).toFixed(2) || 0;
      vals.oneTimeFinalPrivateMembershipPrice = vals.oneTimeFinalPlusMembershipPrice;
      vals.stateCodes = vals?.isStateAddress === true ? vals.stateCodes : null;
      vals.addresss = vals?.isStateAddress === false ? vals.addresss : null;
      vals.radiusForAddressInMiles = vals?.isStateAddress === false
        ? vals.radiusForAddressInMiles : null;

      const programPayload = { ...vals };
      if (programPayload.appointments) {
        delete programPayload.appointments;
      }
      if (programPayload.staff) {
        delete programPayload.staff;
      }
      vals.status = 'inReview';
      if (id === 'new' || searchParams.get('clone')) {
        // insert appointments
        let errorAppointment = false;
        const appointments = vals?.appointments?.map((a) => dispatch(createProgramAppointments(a))
          .then((appointmentPayload) => {
            if (!errorAppointment && _.isEmpty(appointmentPayload?.payload?.error)) {
              a.id = appointmentPayload?.payload.doc.id;
            } else {
              errorAppointment = true;
            }
          }));
        if (appointments?.length > 0 && !isLive()) {
          await Promise.all(appointments);
        }
        if (!errorAppointment) {
          if (vals?.appointments?.length > 0) {
            programPayload.programAppointments = vals?.appointments?.map((a) => (a.id));
          }
          // create program by passing appointmentids
          dispatch(createProgram(programPayload)).then((payload) => {
            if (_.isEmpty(payload.error)) {
              const programId = payload?.payload?.doc.id;
              // insert provider
              if (vals.isSelectStaff === true && vals.staff?.length > 0) {
                let errorStaff = false;
                const staffs = vals?.staff?.map((a) => dispatch(createProgramProvider({
                  provider: a.id, program: programId, status: 'invited', isStaffProvider: true,
                }))
                  .then((staffPayload) => {
                    if (!(!errorStaff && _.isEmpty(staffPayload?.payload?.error))) {
                      errorStaff = true;
                    }
                  }));
                if (staffs?.length > 0) {
                  Promise.all(staffs);
                }
                if (!errorStaff) {
                  completeSaveProgram(vals, programId, programPayload);
                }
              } else {
              // call at the last api hit
                completeSaveProgram(vals, programId, programPayload);
              }
            }
          });
        }
      } else {
        // insert/update
        let errorAppointment = false;
        if (!isLive()) {
          const appointments = vals?.appointments
            ?.map((a) => dispatch(a?.id ? updateProgramAppointments({
              id: a.id,
              params: { ...a, live: isLive() },
            })
              : createProgramAppointments({ ...a, live: isLive() }))
              .then((appointmentPayload) => {
                if (!errorAppointment && _.isEmpty(appointmentPayload?.payload?.error)) {
                  a.id = appointmentPayload?.payload.doc.id;
                } else {
                  errorAppointment = true;
                }
              }));
          if (appointments?.length > 0) {
            await Promise.all(appointments);
          }
        } else {
          const appointments = vals?.appointments
            ?.map((a) => dispatch(updateProgramAppointments({
              id: a.id,
              params: { 'appointmentName': a.appointmentName, 'appointmentDescription': a.appointmentDescription, live: isLive() },
            })));
          if (appointments?.length > 0) {
            await Promise.all(appointments);
          }
        }

        // del appointments
        if (delApmt?.length > 0 && !isLive()) {
          let errorDelAppointment = false;
          const delAppointments = delApmt?.map((a) => dispatch(updateProgramAppointments({
            id: a,
            params: {
              live: isLive(),
              deletedInfoField: {
                isDeleted: true,
              },
            },
          }))
            .then((appointmentPayload) => {
              if (!(!errorDelAppointment && _.isEmpty(appointmentPayload?.payload?.error))) {
                errorDelAppointment = true;
              }
            }));
          if (delAppointments?.length > 0) {
            await Promise.all(delAppointments);
          }
        }
        if (delStaff?.length > 0) {
          let errorDelProvider = false;
          const delStaffs = delStaff?.map((a) => dispatch(updateProgramProvider({
            id: a,
            params: {
              deletedInfoField: {
                isDeleted: true,
              },
            },
          }))
            .then((appointmentPayload) => {
              if (!(!errorDelProvider && _.isEmpty(appointmentPayload?.payload?.error))) {
                errorDelProvider = true;
              }
            }));
          if (delStaff?.length > 0) {
            await Promise.all(delStaffs);
          }
        }

        // insert/update/del provider
        if (vals.isSelectStaff === true && vals.staff?.length > 0) {
          let errorStaff = false;
          const staffs = vals?.staff?.filter((a) => a?.pid === undefined && a?.pid !== null)
            ?.map((a) => dispatch(createProgramProvider({
              provider: a.id, program: id, status: 'invited', isStaffProvider: true,
            }))
              .then((staffPayload) => {
                if (!(!errorStaff && _.isEmpty(staffPayload?.payload?.error))) {
                  errorStaff = true;
                }
              }));
          if (staffs?.length > 0) {
            Promise.all(staffs);
          }
          if (!error) {
            completeSaveProgram(vals, id, programPayload);
          }
        } else {
        // call at the last api hit
          completeSaveProgram(vals, id, programPayload);
        }
      }
    } else {
      setError(true);
      toast.error('Please fill the entire form');
    }
  };

  const getCopyIndex = () => {
    let index = 0;
    if (form.getFieldValue('isOneTimePayment') === true) {
      const infoOnlyExist = form.getFieldValue('appointments')?.filter((a) => a?.appointmentTypeInfo === true)?.length > 0;
      if (infoOnlyExist) {
        index = 1;
      }
    }
    return index;
  };

  const onChangeValue = (key, val) => {
    if (key === 'programDurationInMonths') {
      if (form.getFieldValue('minimumEnrollmentMonthsForCancelFee') > 0) {
        form.validateFields(['minimumEnrollmentMonthsForCancelFee']);
      }
    } else if (key === 'isRegionDependent' && val === false) {
      form.setFields([{ name: 'stateCodes', value: [] },
        { name: 'isStateAddress', value: null },
        { name: ['address', 'addressLine1'], value: null },
        { name: 'radiusForAddressInMiles', value: null },
        { name: 'stateCodes', value: [] },
        { name: ['address', 'city'], value: null },
        { name: ['address', 'stateCode'], value: null },
        { name: ['address', 'postalCode'], value: null },
        { name: 'address', value: null }]);
    } else if (key === 'programType') {
      if (val === 'employerBasedProgram') {
        form.setFields([{ name: 'memberLimitType', value: 'unlimited' }, { name: 'maxMembers', value: null },
          { name: ['programPricing', 'messagingPrice'], value: 0.00 },
          { name: ['optInPricing', 'messagingPrice'], value: 0.00 },
          { name: ['programPricing', 'messagingQuantity'], value: 0 },
        ]);
      } else {
        form.setFields([{ name: 'isCheckoutRequired', value: null }, { name: 'memberLimitType', value: null }, { name: 'maxMembers', value: null }]);
        if (form.getFieldValue('isOnlineOnly') === false) {
          form.setFields([{ name: ['programPricing', 'messagingPrice'], value: 0.00 },
            { name: ['optInPricing', 'messagingPrice'], value: 0.00 },
            { name: ['programPricing', 'messagingQuantity'], value: 0 },
          ]);
        }
      }
      form.setFields([
        { name: 'appointments', value: [] },
        { name: 'unstructuredProcedures', value: [] },
        { name: 'labs', value: [] },
        { name: ['programPricing', 'pharmaceuticalAndMaterialPrice'], value: 0 },
        { name: ['programPricing', 'deliveryFee'], value: 0 },
        { name: 'assessmentFee', value: 0 },
        { name: ['programPricing', 'totalProgramPrice'], value: 0 },
        { name: ['programPricing', 'totalAtHomeLabsPrice'], value: 0 },
        { name: ['programPricing', 'programPricePerMonth'], value: 0 },
        { name: 'finalBasicMembershipPrice', value: 0 },
        { name: 'finalPlusMembershipPrice', value: 0 },
        { name: ['programPricing', 'ingredientInflationAmountPercentage'], value: 0 }]);

      setRefresh(!refresh);
    } else if (key === 'programStructureType') {
      if (val === 'structured') {
        form.setFields([{ name: 'appointments', value: [{ appointmentName: null }] }]);
      } else if (val === 'unstructured') {
        const delItems = form.getFieldValue('appointments')?.map((x) => (x?.id !== undefined ? x.id : null))?.filter((x) => x !== null);
        setDelApmt(delItems);
        form.setFields([{ name: 'appointments', value: [] },
          { name: 'unstructuredProcedures', value: [] },
          { name: 'labs', value: [] },
          { name: 'isOnlineOnly', value: false },
          { name: ['programPricing', 'messagingPrice'], value: null },
          { name: ['optInPricing', 'messagingPrice'], value: null },
          { name: ['programPricing', 'messagingQuantity'], value: null },
        ]);
      } else if (form.getFieldValue('appointments')?.length === 0 || form.getFieldValue('appointments')?.length === undefined) {
        form.setFields([{ name: 'appointments', value: [{ appointmentName: null }] }]);
      }

      if (form.getFieldValue('isOnlineOnly') === true) {
        form.setFields([
          { name: ['programPricing', 'houseCallPrice'], value: 0.00 },
          { name: ['programPricing', 'houseCallQuantity'], value: 0 },
          { name: ['programPricing', 'luxeMobileClinicsPrice'], value: 0.00 },
          { name: ['programPricing', 'luxeMobileClinicsQuantity'], value: 0 },
          { name: ['programPricing', 'facilityPrice'], value: 0.00 },
          { name: ['programPricing', 'facilityQuantity'], value: 0 },
          { name: ['optInPricing', 'houseCallPrice'], value: 0.00 },
          { name: ['optInPricing', 'luxeMobileClinicsPrice'], value: 0.00 },
          { name: ['optInPricing', 'facilityPrice'], value: 0.00 },
          { name: ['optInPricing', 'telemedicinePrice'], value: 0.00 },
          { name: ['programPricing', 'telemedicinePrice'], value: 0.00 },
          { name: ['programPricing', 'telemedicineQuantity'], value: 0 },
          { name: ['programPricing', 'messagingPrice'], value: null },
          { name: ['optInPricing', 'messagingPrice'], value: null },
          { name: ['programPricing', 'messagingQuantity'], value: null },
        ]);
      } else {
        form.setFields([
          { name: ['programPricing', 'houseCallPrice'], value: null },
          { name: ['programPricing', 'houseCallQuantity'], value: null },
          { name: ['programPricing', 'luxeMobileClinicsPrice'], value: null },
          { name: ['programPricing', 'luxeMobileClinicsQuantity'], value: null },
          { name: ['programPricing', 'facilityPrice'], value: null },
          { name: ['programPricing', 'facilityQuantity'], value: null },
          { name: ['programPricing', 'telemedicinePrice'], value: null },
          { name: ['programPricing', 'telemedicineQuantity'], value: null },
          { name: ['programPricing', 'messagingPrice'], value: 0.00 },
          { name: ['optInPricing', 'messagingPrice'], value: 0.00 },
          { name: ['programPricing', 'messagingQuantity'], value: 0 },
        ]);
      }
    } else if ((key === 'isSelectStaff' && val === false) || (key === 'isAutoOptIn' && val === true)) {
      const delItems = form.getFieldValue('staff')?.map((x) => (x?.pid !== undefined ? x.pid : null))?.filter((x) => x !== null);
      setDelStaff(delItems);
      form.setFields([{ name: 'staff', value: [] }]);
      setProviderWarning([]);
    } else if (key === 'isOnlineOnly') {
      if (val === true) {
        form.setFields([
          { name: ['programPricing', 'houseCallPrice'], value: 0.00 },
          { name: ['programPricing', 'houseCallQuantity'], value: 0 },
          { name: ['programPricing', 'luxeMobileClinicsPrice'], value: 0.00 },
          { name: ['programPricing', 'luxeMobileClinicsQuantity'], value: 0 },
          { name: ['programPricing', 'facilityPrice'], value: 0.00 },
          { name: ['programPricing', 'facilityQuantity'], value: 0 },
          { name: ['optInPricing', 'houseCallPrice'], value: 0.00 },
          { name: ['optInPricing', 'luxeMobileClinicsPrice'], value: 0.00 },
          { name: ['optInPricing', 'facilityPrice'], value: 0.00 },
          { name: ['optInPricing', 'telemedicinePrice'], value: 0.00 },
          { name: ['programPricing', 'telemedicinePrice'], value: 0.00 },
          { name: ['programPricing', 'telemedicineQuantity'], value: 0 },
          { name: ['programPricing', 'messagingPrice'], value: null },
          { name: ['optInPricing', 'messagingPrice'], value: null },
          { name: ['programPricing', 'messagingQuantity'], value: null },
        ]);
        if ((form.getFieldValue('programStructureType') === 'structured' || form.getFieldValue('programStructureType') === 'both') && form.getFieldValue('appointments')?.length > 0) {
          const disabledDeliveryMethods = ['facility', 'van', 'house-call', 'telemedicine'];
          form.getFieldValue('appointments')?.forEach((a, index) => {
            if (a.deliveryMethod?.length > 0) {
              deliveryMethods
                .docs.forEach((o) => {
                  if (disabledDeliveryMethods?.includes(o.internalIdentifier)
                  && o.id === a.deliveryMethod[0]) {
                    form.setFields([
                      { name: ['appointments', index, 'deliveryMethod'], value: [] },
                    ]);
                    setRefresh(!refresh);
                  }
                });
            }
            if (a.procedures?.length > 1) {
              form.setFields([
                { name: ['appointments', index, 'procedures'], value: [] },
              ]);
              setRefresh(!refresh);
            }
          });
        }
      } else {
        form.setFields([
          { name: ['programPricing', 'houseCallPrice'], value: null },
          { name: ['programPricing', 'houseCallQuantity'], value: null },
          { name: ['programPricing', 'luxeMobileClinicsPrice'], value: null },
          { name: ['programPricing', 'luxeMobileClinicsQuantity'], value: null },
          { name: ['programPricing', 'facilityPrice'], value: null },
          { name: ['programPricing', 'facilityQuantity'], value: null },
          { name: ['optInPricing', 'houseCallPrice'], value: null },
          { name: ['optInPricing', 'luxeMobileClinicsPrice'], value: null },
          { name: ['optInPricing', 'facilityPrice'], value: null },
          { name: ['optInPricing', 'telemedicinePrice'], value: null },
          { name: ['programPricing', 'telemedicinePrice'], value: null },
          { name: ['programPricing', 'telemedicineQuantity'], value: null },
          { name: ['programPricing', 'messagingPrice'], value: 0.00 },
          { name: ['optInPricing', 'messagingPrice'], value: 0.00 },
          { name: ['programPricing', 'messagingQuantity'], value: 0 },
        ]);
      }
    } else if (key.includes('appointmentType')) {
      const index = key.split('.')[1];
      if (key.includes('appointmentTypeAssessment')) {
        if (val !== true) {
          form.setFields([
            { name: ['appointments', index, 'assessmentFee'], value: 0 },
          ]);
        } else {
          form.setFields([
            { name: ['appointments', index, 'appointmentTypeInfo'], value: false },
          ]);
        }
      }
      if (key.includes('appointmentTypeInfo') && val === true) {
        const disabledDeliveryMethods = [];
        const appDeliveryMethod = form.getFieldValue(['appointments', index, 'deliveryMethod']);
        if (appDeliveryMethod?.length > 0) {
          deliveryMethods
            .docs.forEach((o) => {
              if (disabledDeliveryMethods?.includes(o.internalIdentifier)
                  && o.id === appDeliveryMethod[0]) {
                form.setFields([
                  { name: ['appointments', index, 'deliveryMethod'], value: [] },
                  { name: ['appointments', index, 'procedures'], value: [] },
                ]);
                setRefresh(!refresh);
              }
            });
        }
        // filter procedure if we change to informational to allow only general procedure
        const refreshedDlMethod = form.getFieldValue(['appointments', index, 'deliveryMethod']);
        if (refreshedDlMethod?.length > 0) {
          const currentAppoinmentProcedure = form.getFieldValue(['appointments', index, 'procedures']);
          form.setFields([
            { name: ['appointments', index, 'procedures'], value: currentAppoinmentProcedure?.filter((a) => a?.deliveryMethods?.map((d) => d.id)?.includes(val[0]) && a?.isGeneralProcedure === true) }]);
          setRefresh(!refresh);
        }
        form.setFields([
          { name: ['appointments', index, 'assessmentFee'], value: 0 },
          { name: ['appointments', index, 'appointmentTypeAssessment'], value: false },
        ]);
      }
      setRefresh(!refresh);
      return;
    } else if (key === 'isOneTimePayment' && val === true) {
      const copyIndex = getCopyIndex();
      if ((form.getFieldValue('programStructureType') === 'structured' || form.getFieldValue('programStructureType') === 'both') && form.getFieldValue('appointments')?.length > copyIndex) {
        const proceduresforFirstAppointment = form.getFieldValue(['appointments', copyIndex, 'procedures']);
        const data = form.getFieldValue('appointments')?.map((a, i) => ({
          ...a,
          procedures: i > copyIndex ? proceduresforFirstAppointment : a?.procedures,
        }));

        form.setFields([{ name: 'appointments', value: data }]);
      }
    } else if (key.includes('deliveryMethod')) {
      // if one time copy from source and filter if not informational appointment
      const index = key.split('.')[1];
      const copyIndex = getCopyIndex();
      if (form.getFieldValue('appointments')?.length > copyIndex && form.getFieldValue('isOneTimePayment') === true
        && form.getFieldValue(['appointments', index, 'appointmentTypeInfo']) !== true) {
        const procedureSource = form.getFieldValue(['appointments', copyIndex, 'procedures']);
        form.setFields([
          { name: ['appointments', index, 'procedures'], value: procedureSource }]);
        setRefresh(!refresh);
      }

      // values is there filter by delivery mothod
      let currentAppoinmentProcedure = [];
      if (val?.length > 0) {
        currentAppoinmentProcedure = form.getFieldValue(['appointments', index, 'procedures']);
        if (currentAppoinmentProcedure) {
          if (form.getFieldValue(['appointments', index, 'appointmentTypeInfo']) === true) {
            currentAppoinmentProcedure = currentAppoinmentProcedure
              ?.filter((p) => p?.isGeneralProcedure === true);
          }
          currentAppoinmentProcedure = currentAppoinmentProcedure
            ?.filter((a) => a.deliveryMethods?.map((d) => d.id)?.includes(val[0]));
          form.setFields([
            { name: ['appointments', index, 'procedures'], value: currentAppoinmentProcedure }]);
        }
        setRefresh(!refresh);
      } else {
        form.setFields([
          { name: ['appointments', index, 'procedures'], value: currentAppoinmentProcedure }]);
      }
    } else if (key.includes('procedures') && (form.getFieldValue('programStructureType') === 'structured' || form.getFieldValue('programStructureType') === 'both') && form.getFieldValue('isOneTimePayment') === true) {
      const copyIndex = getCopyIndex();
      if (form.getFieldValue('appointments')?.length > copyIndex) {
        const proceduresforFirstAppointment = form.getFieldValue(['appointments', copyIndex, 'procedures']);
        const data = form.getFieldValue('appointments')?.map((a, i) => ({
          ...a,
          procedures: i > copyIndex ? proceduresforFirstAppointment : a?.procedures,
        }));
        form.setFields([{ name: 'appointments', value: data }]);
      }
    } else if (key === 'isStateAddress') {
      form.setFields([{ name: ['address', 'addressLine1'], value: null },
        { name: 'radiusForAddressInMiles', value: null },
        { name: 'stateCodes', value: [] },
        { name: ['address', 'city'], value: null },
        { name: ['address', 'stateCode'], value: null },
        { name: ['address', 'postalCode'], value: null }]);
    } else if (key === 'applicableMemberTypes' || key === 'petTypes') {
      if (key === 'applicableMemberTypes' && !val?.includes('pet')) {
        form.setFields([{ name: 'petTypes', value: [] }]);
      }
      form.getFieldValue('appointments')?.forEach((a, index) => {
        if (a.procedures?.length > 0) {
          const procedures = a.procedures.filter((o) => {
            const applicableMemberTypes = form.getFieldValue('applicableMemberTypes') || [];
            const petTypes = form.getFieldValue('petTypes') || [];

            // Function to check if all items in one array are contained in another
            const arrayContainsAll = (sourceArray, targetArray) => {
              if (!sourceArray || !targetArray) return false; // Handle undefined/null cases
              return targetArray.every((item) => sourceArray.includes(item));
            };

            // Check if applicableMemberTypes contains all required items
            const hasApplicableMemberType = arrayContainsAll(
              applicableMemberTypes,
              o?.applicableMemberTypes || [],
            );

            // Check if petTypes contains all required items
            const hasPetType = o?.petTypes && arrayContainsAll(petTypes, o.petTypes);

            return hasApplicableMemberType && (petTypes.length > 0 ? hasPetType : true);
          }) || [];

          // Update the form field
          form.setFields([
            { name: ['appointments', index, 'procedures'], value: procedures },
          ]);

          setRefresh(!refresh);
        }
      });
    }
    form.setFields([{ name: key, value: val }]);
    setRefresh(!refresh);
  };

  const addNewAppoinetment = () => {
    const copyIndex = getCopyIndex();
    if ((form.getFieldValue('programStructureType') === 'structured' || form.getFieldValue('programStructureType') === 'both') && form.getFieldValue('isOneTimePayment') === true && form.getFieldValue('appointments')?.length > copyIndex) {
      const proceduresforFirstAppointment = form.getFieldValue(['appointments', copyIndex, 'procedures']);
      const dmforFirstAppointment = form.getFieldValue(['appointments', copyIndex, 'deliveryMethod']);
      const data = form.getFieldValue('appointments')?.map((a, i) => ({
        ...a,
        procedures: i > copyIndex ? proceduresforFirstAppointment : a?.procedures,
        deliveryMethod: i > copyIndex ? dmforFirstAppointment : a?.deliveryMethod,
      }));
      form.setFields([{ name: 'appointments', value: data }]);
    }
  };

  const durationTermfailSafeEmptyFieldValidator = (m, v, k, c) => {
    if (form.getFieldValue(c)) {
      if (form.getFieldValue(c) === k) {
        return failSafeEmptyNonZeroFieldValidator(m, v);
      }
    }
    return Promise.resolve();
  };

  const onGoLive = () => {
    setGoLive(true);
    form.submit();
  };

  const removeLinked = (key, index) => {
    const data = JSON.parse(JSON.stringify(form.getFieldValue(key)));
    if (data && data.length > 0) {
      if (index !== undefined && index !== -1) {
        if (key === 'staff') {
          const del = delStaff;
          del.push(data[index].pid || 0);
          setDelStaff(del?.filter((x) => x !== 0));
        } else if (key === 'appointments') {
          const del = delApmt;
          del.push(data[index]?.id || 0);
          setDelApmt(del?.filter((x) => x !== 0));
          data.forEach((o, i) => {
            if (o?.prerequisiteAppointments?.includes(index)) {
              form.setFields([{ name: ['appointments', i, 'prerequisiteAppointments'], value: [] }]);
            }
          });
        }
        data.splice(index, 1);

        form.setFields([{ name: key, value: data }]);
        setRefresh(!refresh);
        if (data?.length === 0 && (((form.getFieldValue('programStructureType') === 'structured' || form.getFieldValue('programStructureType') === 'both') && key === 'appointments')
          || ((form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (form.getFieldValue('labs')?.length === undefined || form.getFieldValue('labs')?.length === 0) && key === 'unstructuredProcedures')
          || ((form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (form.getFieldValue('unstructuredProcedures')?.length === undefined || form.getFieldValue('unstructuredProcedures')?.length === 0) && key === 'labs'))) {
          form.setFields([{ name: ['programPricing', 'pharmaceuticalAndMaterialPrice'], value: 0 },
            { name: ['programPricing', 'deliveryFee'], value: 0 },
            { name: 'assessmentFee', value: 0 },
            { name: ['programPricing', 'totalProgramPrice'], value: 0 },
            { name: ['programPricing', 'totalAtHomeLabsPrice'], value: 0 },
            { name: ['programPricing', 'programPricePerMonth'], value: 0 },
            { name: 'finalBasicMembershipPrice', value: 0 },
            { name: 'finalPlusMembershipPrice', value: 0 },
            { name: ['programPricing', 'ingredientInflationAmountPercentage'], value: 0 }]);
        } else if ((form.getFieldValue('programStructureType') === 'structured' && (key === 'appointments' || key.includes('labs') || key.includes('procedures')))
        || (form.getFieldValue('programStructureType') === 'unstructured' && (key === 'unstructuredProcedures' || key === 'labs'))
        || (form.getFieldValue('programStructureType') === 'both' && (key === 'appointments' || key.includes('labs') || key.includes('procedures')))) {
          calculatePrice();
        }
        setRefresh(!refresh);
      }
    }
  };

  const durationCompare = (m, v) => {
    const compareTo = form.getFieldValue('programDurationInMonths');
    if (compareTo < v) {
      return Promise.reject(m);
    }
    return Promise.resolve();
  };

  const onShowPopup = (key) => {
    if (key === 'staff') {
      setDialogOpen({
        title: 'Select Staff', isOpen: true, isPreview: false, isStaff: true,
      });
    } else {
      setDialogOpen({
        title: 'Program Preview', isOpen: true, isPreview: true, isStaff: false,
      });
    }
  };

  const onCancelDialogPopup = () => {
    setDialogOpen({
      title: '', isOpen: false, isPreview: false, isStaff: false,
    });
  };

  const isViewMode = () => {
    let mode = false;
    if ((selectedProgram?.status === 'live'
      || selectedProgram?.status === 'cancelled'
      || selectedProgram?.status === 'paused'
      || selectedProgram?.status === 'full'
      || selectedProgram?.status === 'suspended') && !searchParams.get('clone')) {
      mode = true;
    }
    return mode;
  };

  const getProviderType = (providerType) => {
    let text = '';
    if (providerType) {
      const name = providerTypes?.docs?.find((p) => p.id === providerType)?.providerTypeName;
      text = name ? ` (${name})` : '';
    }
    return text;
  };

  const getFilterState = () => {
    let filter = null;
    if (form.getFieldValue('isRegionDependent') === true) {
      let stateCodes = [];
      if (form.getFieldValue('isStateAddress') === true) {
        stateCodes = form.getFieldValue('stateCodes');
      } else if (form.getFieldValue('isStateAddress') === false) {
        stateCodes = [form.getFieldValue('address')?.stateCode]?.filter((x) => x !== undefined && x !== null);
      }
      if (stateCodes?.length > 0) {
        filter = stateCodes?.reduce((acc, o, i) => {
          acc[`where[or][${i}][licenses.${o}.licenseExpirationDate][greater_than]`] = new Date().toISOString();
          return acc;
        }, {});
      }
    }
    return filter;
  };

  const onChangeAddress = (v) => {
    if (v?.length > 3) {
      const params = {
        'q': v,
      };
      if (searchAddressRequest) {
        searchAddressRequest.abort();
      }
      const searchAddress = dispatch(searchUsAddress(params));
      setSearchAddressRequest(searchAddress);
    }
  };

  const onSelectAddress = (v, o) => {
    if (o.item) {
      form.setFields([{ name: ['address', 'addressLine1'], value: o?.item?.addressLine1 },
        { name: ['address', 'stateCode'], value: o?.item?.stateCode },
        { name: ['address', 'postalCode'], value: o?.item?.postalCode },
        { name: ['address', 'city'], value: o?.item?.city },
        { name: ['address', 'location'], value: o?.item?.location }]);
    }
  };

  const getProcedureFilters = () => {
    const filter = {
      'where[isGeneralProcedure][equals]': true,
      'where[isRevPlusSpecials][not_equals]': true,
      'where[isRevTogether][not_equals]': true,
    };

    form.getFieldValue('applicableMemberTypes')?.forEach((a, i) => {
      filter[`where[applicableMemberTypes][all][${i}]`] = a;
    });

    if (form.getFieldValue('applicableMemberTypes')?.includes('pet')) {
      form.getFieldValue('petTypes')?.forEach((a, i) => {
        filter[`where[petTypes][all][${i}]`] = a;
      });
    }

    return filter;
  };

  return (
    <div className="program-container">
      <Form
        onFinish={handleSubmit}
        form={form}
        onValuesChange={() => forceUpdate((n) => !n)}
        onFinishFailed={() => {
          setError(true);
          toast.error('Please fill the entire form');
        }}
      >
        <div className="program-container__outer">
          <div className="program-container__wrapper">
            <h1>Programs</h1>
            <div className="section">
              <Form.Item
                name="programType"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioGroup
                  label="What Type of Program is this? *"
                  options={[
                    { label: 'Employer-based program', value: 'employerBasedProgram', disabled: (isLive() && form.getFieldValue('programType') !== 'employerBasedProgram') },
                    { label: 'Consumer-based program', value: 'consumerBasedProgram', disabled: (isLive() && form.getFieldValue('programType') !== 'consumerBasedProgram') },
                  ]}
                  onChange={(e) => onChangeValue('programType', e)}
                  disabled={isLive()}
                />
              </Form.Item>
            </div>
            <div className="section">
              <Form.Item
                name="programName"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' },
                  { max: 150, message: 'This Field can not exceed 150 characters' },
                ]}
              >
                <InputField
                  label="Title of Program (Users will see this language in the Member App banner) *"
                  placeholder="Title of Program"
                  className="formfield"
                  maxLength={150}
                />
              </Form.Item>
              <div className="text-length">
                {form.getFieldValue('programName')?.length || 0}
                /150
              </div>
            </div>
            <div className="section">
              <Form.Item
                name="programSpecifications"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <TextAreaField
                  label="What Does This Program Include *"
                  placeholder="What Does This Program Include"
                  rows={3}
                  maxLength={400}
                  className="formfield"
                />
              </Form.Item>
              <div className="text-length">
                {form.getFieldValue('programSpecifications')?.length || 0}
                /400
              </div>
            </div>
            <div className="section">
              <Form.Item
                name="description"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' },
                  { min: 120, message: 'Minimum 120 characters' }]}
              >
                <TextAreaField
                  label="Program Description *"
                  placeholder="Program Description"
                  rows={3}
                  maxLength={1500}
                  minLength={120}
                  className="formfield"
                />
              </Form.Item>
              <div className="text-length">
                {form.getFieldValue('description')?.length || 0}
                /1500
              </div>
            </div>

            <div className="section">
              <Form.Item
                name="termsAndConditions"
                rules={[
                  {
                    validator: (m, v) => {
                      const val = v && v.replace(/&nbsp;/g, '').replace(/ /g, '');
                      if (
                        val === '<style>p{margin:2px0px;}</style>'
                          || val === '<p></p><style>p{margin:2px0px;}</style>' || val === undefined
                      ) {
                        return Promise.reject(
                          new Error('This field is required'),
                        );
                      } if (v?.length < 120) {
                        return Promise.reject(new Error('Minimum 120 characters'));
                      }
                      return Promise.resolve();
                    },
                  },
                  allowOnlyANSI,
                ]}
              >
                <RichTextEditor />
              </Form.Item>
            </div>
            <div className="section" style={{ flexDirection: 'column' }}>
              <label>Upload Photo to be displayed on Program *</label>
              <Form.Item
                name="programImage"
                className="formfield"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <UploadButton
                  accept=".jpg, .jpeg, .png"
                  placeholder="Upload Photo"
                  onChange={(e) => setImageSrc(URL.createObjectURL(e))}
                />
              </Form.Item>
              {imageSrc && (
              <Image
                className="promo-image"
                width={200}
                src={imageSrc}
              />
              )}
            </div>
            <div className="section member-type">
              <Form.Item
                name="applicableMemberTypes"
                className="formfield"
                style={{ display: 'flex' }}
                valuePropName="checked"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <MultiCheckboxSelect
                  label="Who does this program apply to? *"
                  options={[{ label: 'RevAdults', value: 'normal' },
                    { label: 'RevKids (Under 18)', value: 'dependent' },
                    { label: 'RevPets', value: 'pet' },
                  ]}
                  value={form.getFieldValue('applicableMemberTypes')?.filter((x) => x !== -1) || []}
                  onChange={(e) => onChangeValue('applicableMemberTypes', e)}
                />
              </Form.Item>
              {form.getFieldValue('applicableMemberTypes')?.includes('pet') && (
              <div className="program-type-pet">
                <Form.Item
                  name="petTypes"
                  className="formfield"
                  style={{ display: 'flex' }}
                  valuePropName="checked"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                >
                  <MultiCheckboxSelect
                    label="Pet Type *"
                    options={[{ label: 'Cats', value: 'cat' },
                      { label: 'Dogs', value: 'dog' },
                    ]}
                    value={form.getFieldValue('petTypes')?.filter((x) => x !== -1) || []}
                    onChange={(e) => onChangeValue('petTypes', e)}
                  />
                </Form.Item>
              </div>
              )}
            </div>

            <div className="section">
              <Form.Item
                name="isOnlineOnly"
              >
                <RadioGroup
                  label="Is this 100% Online program only?"
                  options={[
                    { label: 'Yes', value: true, disabled: ((isLive() && form.getFieldValue('isOnlineOnly') !== true) || (form.getFieldValue('programStructureType') === 'unstructured') || (form.getFieldValue('programType') === 'employerBasedProgram')) },
                    { label: 'No', value: false, disabled: ((isLive() && form.getFieldValue('isOnlineOnly') !== false) || (form.getFieldValue('programStructureType') === 'unstructured') || (form.getFieldValue('programType') === 'employerBasedProgram')) },
                  ]}
                  onChange={(e) => onChangeValue('isOnlineOnly', e)}
                  disabled={isLive()}

                />
              </Form.Item>
            </div>

            <div className="section">
              <Form.Item
                name="isCheckoutRequired"
                className="formfield radio-promo"
              >
                <RadioGroup
                  label="Does this Program require payment?"
                  options={[
                    { label: 'Yes', value: true, disabled: (isLive() && form.getFieldValue('isCheckoutRequired') !== true) || (form.getFieldValue('programType') !== 'employerBasedProgram') },
                    { label: 'No, bypass checkout', value: false, disabled: (isLive() && form.getFieldValue('isCheckoutRequired') !== false) || (form.getFieldValue('programType') !== 'employerBasedProgram') },
                  ]}
                  disabled={isLive()}
                />
              </Form.Item>
            </div>

            <div className="section">
              <Form.Item
                name="isAffiliationProgram"
                className="formfield radio-promo"
              >
                <RadioGroup
                  label="Is this an Affiliation Program?"
                  options={[
                    { label: 'Yes', value: true, disabled: (isLive() && form.getFieldValue('isAffiliationProgram') !== true) },
                    { label: 'No', value: false, disabled: (isLive() && form.getFieldValue('isAffiliationProgram') !== false) },
                  ]}
                  disabled={isLive()}
                />
              </Form.Item>
            </div>

            <div className="section">
              <Form.Item
                name="isProgramCodeRequired"
                className="formfield radio-promo"
              >
                <RadioGroup
                  label="Does this program requires a Code?"
                  options={[
                    { label: 'Yes', value: true, disabled: (isLive() && form.getFieldValue('isProgramCodeRequired') !== true) },
                    { label: 'No', value: false, disabled: (isLive() && form.getFieldValue('isProgramCodeRequired') !== false) },
                  ]}
                  disabled={isLive()}
                />
              </Form.Item>
            </div>
            {form.getFieldValue('isProgramCodeRequired') === true && (
            <div className="section">
              <Form.Item
                name="programCode"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' },
                  { min: 6, message: 'This field should have at least 6 characters' },
                  { max: 10, message: 'This Field can not exceed 10 characters' },
                ]}
              >
                <InputField
                  label="Program Code *"
                  placeholder="Program Code"
                  className="formfield program-code"
                  maxLength={10}
                  minLength={6}
                  disabled={isLive()}
                />
              </Form.Item>
            </div>
            )}
            <div className="section">
              <div className="promo-cal">
                <Form.Item
                  name="effectiveDate"
                  className="formfield"
                  rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                  getValueProps={(i) => ({ value: i && moment(i) })}
                >
                  <DatePicker
                    label="Program Start/Effective Date *"
                    disabledDate={(d) => !d
                      || d.isBefore(
                        moment(
                          new Date(),
                          'MM-DD-YYYY',
                        ).subtract(1, 'd'),
                      )}
                    defaultPickerValue={moment(new Date(), 'YYYY-MM-DD')}
                    disabled={isLive()}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="section">
              <div>
                <label>How long will this program last? *</label>
              </div>
              <div className="pgm-member drp-month">
                <div className="amount-text">
                  <Form.Item
                    name="programDurationInMonths"
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                    ]}
                  >
                    <DropDown
                      label=""
                      placeholder=""
                      className="biomarker-dropdown"
                      isOptionsObj
                      options={months}
                      disabled={isLive()}
                      onChange={(v) => onChangeValue('programDurationInMonths', v)}
                    />
                  </Form.Item>
                  <span className="member-label">months</span>
                </div>
              </div>
            </div>
            <div className="section">
              <label className="check-label-promo">
                <div>Select Program Category *</div>
              </label>
              {
form.getFieldValue('procedureCategory')?.length > 0 && (
<div className="card">
  {form.getFieldValue('procedureCategory')?.map((category, pIndex) => (
    <div className="card-item" key={`cat${pIndex}`}>
      <div className="left">
        <h5>{`${_.get(category, 'procedureCategoryName', '')}`}</h5>
      </div>
      <div className="del-appointment">
        <Space size="middle">
          <Popconfirm
            title="Are you sure?"
            description="Do you want to delete this category?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => removeLinked('procedureCategory', pIndex)}
          >
            <a>
              <GreyTrashIcon />
            </a>
          </Popconfirm>
        </Space>
      </div>
    </div>
  ))}
</div>
)
          }
              <Form.Item
                name="procedureCategory"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <LinkedProcedure
                  showGrid={false}
                  value={form.getFieldValue('procedureCategory') || []}
                  onChange={(v) => {
                    onChangeValue('procedureCategory', [{ ...v }]);
                  }}
                  multiple={false}
                  label="Select Program Category *"
                  tag="Category"
                  buttonText="Select Category"
                  key={1}
                  showIcon
                  showLabel={false}
                />
              </Form.Item>
            </div>
            <div className="section">
              <div>
                <label>How many members can join this program? *</label>
              </div>
              <Form.Item
                name="memberLimitType"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioButton
                  className="procedure-radio"
                  label="Unlimited number of members"
                  checked={form.getFieldValue('memberLimitType') === 'unlimited'}
                  disabled={isLive() || form.getFieldValue('programType') === 'employerBasedProgram'}
                  value="unlimited"
                  onChange={() => onChangeValue('memberLimitType', 'unlimited')}
                />
                <div className="pgm-member">
                  <RadioButton
                    className="procedure-radio"
                    label=""
                    checked={form.getFieldValue('memberLimitType') === 'specificNumber'}
                    disabled={isLive() || form.getFieldValue('programType') === 'employerBasedProgram'}
                    value="specificNumber"
                    onChange={() => onChangeValue('memberLimitType', 'specificNumber')}
                  />

                  <div className="amount-member-text assessment-text">
                    <Form.Item
                      name="maxMembers"
                      rules={[
                        {
                          validator: (key, val) => durationTermfailSafeEmptyFieldValidator(key, val, 'specificNumber', 'memberLimitType'),
                          message: 'This field is required',
                        },
                        allowOnlyNumbers,
                      ]}
                    >
                      <InputField
                        suffix="#"
                        type="number"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('memberLimitType') !== 'specificNumber'}
                      />
                    </Form.Item>
                    <span className="member-label">members</span>
                  </div>
                </div>

              </Form.Item>
            </div>
            <div className="section checkbox-prgm">
              <label className="check-label-promo">
                <div>What Membership level will this program apply to? *</div>
              </label>
              <Form.Item
                name="revBasic"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="REV1" disabled={isLive()} />
              </Form.Item>
              <Form.Item
                name="revPlus"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="REV+" disabled={isLive()} />
              </Form.Item>
              <Form.Item
                name="revPrivate"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="REVPRIVATE" disabled={isLive()} />
              </Form.Item>
              {
  ((form.isFieldTouched('revBasic') || form.isFieldTouched('revPlus') || form.isFieldTouched('revPrivate') || error)
  && (form.getFieldValue('revBasic') === undefined || form.getFieldValue('revBasic') === false)
  && (form.getFieldValue('revPrivate') === undefined || form.getFieldValue('revPrivate') === false)
  && (form.getFieldValue('revPlus') === undefined || form.getFieldValue('revPlus') === false)
  && (<div className="promo-error">This field is required</div>))
}
            </div>
            <div className="section">
              <Form.Item
                name="isRegionDependent"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioGroup
                  label="Region Dependent? *"
                  onChange={(e) => onChangeValue('isRegionDependent', e)}
                  options={[
                    { label: 'Yes', value: true },
                    { label: 'No (Applies to all regions)', value: false },
                  ]}
                />
              </Form.Item>
              { form.getFieldValue('isRegionDependent') === true && (
                <div className="last-section">
                  <Form.Item
                    name="isStateAddress"
                    className="formfield radio-promo"
                    rules={[{ required: true, message: 'This field is required' }]}
                  >
                    <RadioGroup
                      label="Select State(s)/Address *"
                      onChange={(e) => onChangeValue('isStateAddress', e)}
                      options={[
                        { label: 'State(s)', value: true },
                        { label: 'Address', value: false },
                      ]}
                    />
                  </Form.Item>
                </div>
              )}
              <div>
                {(form.getFieldValue('isRegionDependent') === true && form.getFieldValue('isStateAddress') === true) && (
                <div className="last-section">
                  <div className="last-section state-multi-select">
                    <Form.Item
                      name="stateCodes"
                      className="formfield radio-promo"
                      rules={[{ required: true, message: 'This field is required' }]}
                    >
                      <Select
                        mode="multiple"
                        placeholder="Select State(s)"
                        listHeight={200}
                        suffixIcon={(
                          <div>
                            <svg width="14" height="10" viewBox="0 0 14 10" fill="none" xmlns="http://www.w3.org/2000/svg">
                              <path d="M0.495053 2.42424L5.95521 8.79612C6.04322 8.89877 6.1524 8.98117 6.27525 9.03766C6.3981 9.09415 6.53171 9.1234 6.66693 9.1234C6.80214 9.1234 6.93576 9.09415 7.05861 9.03766C7.18146 8.98117 7.29064 8.89877 7.37865 8.79612L12.8388 2.42424C13.3599 1.81604 12.9279 0.876587 12.1271 0.876587H1.20521C0.404428 0.876587 -0.027603 1.81604 0.495053 2.42424Z" fill="#393B3D" />
                            </svg>
                          </div>
        )}
                        optionFilterProp="label"
                        onChange={(v) => {
                          onChangeValue('stateCodes', v);
                        }}
                        style={{ width: '100%' }}
                        options={uniqueStates.map((item) => ({
                          value: item.stateCode,
                          label: item.stateName,
                        }))?.sort((a, b) => (a.label < b.label ? -1 : 1))}
                      />
                    </Form.Item>
                  </div>
                </div>
                )}
                {(form.getFieldValue('isRegionDependent') === true && form.getFieldValue('isStateAddress') === false) && (
                  <div className="last-section address-search">
                    <AutoComplete
                      options={!isLoading && (address || [])}
                      onSelect={(v, o) => onSelectAddress(v, o)}
                      onChange={(v) => onChangeAddress(v)}
                      waitTime={1300}
                      allowClear
                      placeholder="Search Address"
                    />
                    <div className="last-section">
                      <Form.Item
                        name={['address', 'addressLine1']}
                        rules={[{ required: true, message: 'This field is required' },
                        ]}
                      >
                        <InputField
                          label="Address Line 1 *"
                          placeholder="Address Line 1"
                          className="formfield"
                          disabled
                        />
                      </Form.Item>
                    </div>
                    <div className="last-section row">
                      <div className="sub-col">
                        <Form.Item
                          name={['address', 'city']}
                          rules={[{ required: true, message: 'This field is required' },
                          ]}
                        >
                          <InputField
                            label="City *"
                            placeholder="City"
                            className="formfield"
                            disabled
                          />
                        </Form.Item>
                      </div>
                      <div className="sub-col">
                        <Form.Item
                          name={['address', 'stateCode']}
                          rules={[{ required: true, message: 'This field is required' },
                          ]}
                        >
                          <InputField
                            label="State *"
                            placeholder="State"
                            className="formfield"
                            disabled
                          />
                        </Form.Item>
                      </div>
                      <div className="sub-col">
                        <Form.Item
                          name={['address', 'postalCode']}
                          rules={[{ required: true, message: 'This field is required' },
                          ]}
                        >
                          <InputField
                            label="Zip *"
                            placeholder="Zip"
                            className="formfield"
                            disabled
                          />
                        </Form.Item>
                        <Form.Item
                          name={['address', 'location']}
                        >
                          <InputField type="hidden" />
                        </Form.Item>
                      </div>
                    </div>
                    <div className="last-section">
                      <div className="lbl-name">
                        <label>
                          Radius For Address In Miles *
                        </label>
                      </div>
                      <div className="amount-text">
                        <Form.Item
                          name="radiusForAddressInMiles"
                          rules={[{ required: true, message: 'This field is required' },
                            allowOnlyNumbers,
                          ]}
                        >
                          <InputField
                            placeholder="0"
                            className="formfield"
                            type="number"
                          />
                        </Form.Item>
                      </div>
                    </div>
                  </div>
                )}
              </div>
            </div>
            <div className="last-section checkbox-prgm">
              <label className="check-label-promo">
                <div>Will all providers be automatically opted-in to this program? *</div>
              </label>
              <Form.Item
                name="isAutoOptIn"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="Yes" onChange={(e) => onChangeValue('isAutoOptIn', e?.target?.checked)} disabled={form.getFieldValue('isVoluntarilyOptIn') === true || form.getFieldValue('isSelectStaff') === true} />
              </Form.Item>
              <Form.Item
                name="isSelectStaff"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox label="Select Staff" disabled={form.getFieldValue('isAutoOptIn') === true} onChange={(e) => onChangeValue('isSelectStaff', e?.target?.checked)} />
              </Form.Item>
              <Form.Item
                name="isVoluntarilyOptIn"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox disabled={form.getFieldValue('isAutoOptIn') === true} label="Voluntarily Opt-in" onChange={(e) => onChangeValue('isVoluntarilyOptIn', e?.target?.checked)} />
              </Form.Item>
              {
  ((form.isFieldTouched('isAutoOptIn') || form.isFieldTouched('isVoluntarilyOptIn') || form.isFieldTouched('isSelectStaff') || error)
  && (form.getFieldValue('isAutoOptIn') === undefined || form.getFieldValue('isAutoOptIn') === false)
  && (form.getFieldValue('isSelectStaff') === undefined || form.getFieldValue('isSelectStaff') === false)
  && (form.getFieldValue('isVoluntarilyOptIn') === undefined || form.getFieldValue('isVoluntarilyOptIn') === false)
  && (<div className="promo-error">This field is required</div>))
}
            </div>
            {form.getFieldValue('isSelectStaff') === true && (
            <div className="last-section pgm-top-border">
              <label className="check-label-promo">
                <div>Staff Available for Program *</div>
              </label>
              {
form.getFieldValue('staff')?.length > 0 && (
<div className="card">
  {form.getFieldValue('staff')?.map((provider, pIndex) => (
    <div className="card-item" key={`sta${pIndex}`}>
      <div className="left">
        {providerWarning.includes(provider?.id) && <WarnigAlertlIcon />}
        {_.get(provider, 'biography.providerPhoto.url', '') && (
        <img src={_.get(provider, 'biography.providerPhoto.url', '')} alt="" width={80} height={80} />
        )}
        <h5>{`${_.get(provider, 'firstName', '')} ${_.get(provider, 'middleName', '')}, ${_.get(provider, 'lastName', '')}, ${getProviderType(_.get(provider, 'providerType', ''))}`}</h5>
        {!Object.values(getChecklists(provider) || {}).find((o) => !o.verified) && (
        <p style={{ alignItems: 'center', display: 'flex' }}>
          <BlueTick />
        </p>
        )}
      </div>
      <div className="del-appointment">
        <Space size="middle">
          <Popconfirm
            title="Are you sure?"
            description="Do you want to delete this staff?"
            okText="Yes"
            cancelText="No"
            onConfirm={() => removeLinked('staff', pIndex)}
          >
            <a>
              <GreyTrashIcon />
            </a>
          </Popconfirm>
        </Space>
      </div>
    </div>
  ))}
</div>
)
          }
              {isViewMode()
                ? (
                  <div>
                    <div className="add-icon" onClick={() => onShowPopup('staff')}>
                      <PlusIcon />
                      <label>Invite/Opt-Out Existing Staff</label>
                    </div>
                  </div>
                ) : null}
              <Form.Item
                name="staff"
                rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              >
                <LinkedProcedure
                  showGrid={false}
                  value={(form.getFieldValue('staff') && JSON.parse(JSON.stringify(form.getFieldValue('staff')))) || []}
                  onChange={(v) => {
                    onChangeValue('staff', v);
                  }}
                  multiple
                  label="Staff Available for Program *"
                  tag="Provider"
                  buttonText="Select Staff"
                  key={2}
                  showIcon
                  showLabel={false}
                  filter={getFilterState()}
                />
              </Form.Item>
              {
  (error && providerWarning?.length > 0
  && (<div className="promo-error">Remove invalid Staff</div>))
}
            </div>
            )}
          </div>
        </div>

        <div className="program-container__outer program-assessment">
          <div className="program-container__wrapper">
            <h1>Programs</h1>
            {/* <div className="section">
              <Form.Item
                name="isOneTimePayment"
                rules={[]}
                style={{ display: 'flex' }}
                valuePropName="checked"
              >
                <CheckBox
                  label="Does this Program apply to 'One Time + Program' payment plan?"
                  onChange={(e) => onChangeValue('isOneTimePayment', e?.target?.checked)}
                />
              </Form.Item>
            </div> */}
            <div className="section">
              <div className="lbl-name">
                <label>Cancellation fee *</label>
              </div>
              <div className="amount-text assessment-text">
                <div className="lbl-name">
                  <label>$</label>
                </div>
                <Form.Item
                  name="cancellationFee"
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                    allowDecimalNumbers,
                  ]}
                >
                  <InputField
                    suffix="$"
                    type="number"
                    placeholder="00.00"
                    className="formfield"
                    disabled={isLive()}
                  />
                </Form.Item>
              </div>
            </div>
            <div className="last-section">
              <div className="lbl-name">
                <label>
                  Minimum amount of time for a member to be
                  enrolled before cancellation fee kicks in. *
                </label>
              </div>
              <div className="amount-text">
                <Form.Item
                  name="minimumEnrollmentMonthsForCancelFee"
                  rules={[
                    allowOnlyNumbers,
                    {
                      validator: durationCompare,
                      message: 'This field should be less than or equal to Program Duration',
                    },
                    {
                      validator: failSafeEmptyNonZeroFieldValidator,
                      message: 'This field is required',
                    },
                  ]}
                >
                  <InputField
                    type="number"
                    className="formfield"
                    disabled={isLive()}
                    onChange={(e) => onChangeValue('minimumEnrollmentMonthsForCancelFee', e?.target?.value)}
                  />
                </Form.Item>
                <span className="member-label">months</span>
              </div>
            </div>
          </div>
        </div>

        <div className="program-container__outer program-section">
          <div className="program-container__wrapper">
            <h1>Program Structure</h1>
            <div className="last-section">
              <Form.Item
                name="programStructureType"
                className="formfield radio-promo"
                rules={[{ required: true, message: 'This field is required' }]}
              >
                <RadioButton
                  className="procedure-radio"
                  label="Both"
                  checked={form.getFieldValue('programStructureType') === 'both'}
                  value="both"
                  disabled={isLive()}
                  onChange={(e) => onChangeValue('programStructureType', e)}
                />
                <RadioButton
                  className="procedure-radio"
                  label="Structured (Appointment-based program with dependencies.)"
                  checked={form.getFieldValue('programStructureType') === 'structured'}
                  value="structured"
                  disabled={isLive()}
                  onChange={(e) => onChangeValue('programStructureType', e)}
                />
                {
                  (form.getFieldValue('programStructureType') === 'structured' || form.getFieldValue('programStructureType') === 'both')
                  && ((form.getFieldValue('applicableMemberTypes')?.includes('normal') || form.getFieldValue('applicableMemberTypes')?.includes('dependent'))
                  || (form.getFieldValue('applicableMemberTypes')?.includes('pet') && (form.getFieldValue('petTypes')?.includes('dog') || form.getFieldValue('petTypes')?.includes('cat'))))
                  && (
                    <Form.List
                      name="appointments"
                      rules={[
                        {
                          validator: emptyFieldValidator,
                          message: 'This field is required',
                        },
                        blackListedChars,
                      ]}
                    >
                      {(fields, { add }) => (
                        <>
                          {fields.map((appointment, index) => (
                            <Appointments
                              {...fields}
                              form={form}
                              index={index}
                              appointment={appointment}
                              key={`appointment_${index}`}
                              remove={() => { removeLinked('appointments', index); }}
                              onChangeValue={onChangeValue}
                              removeLinked={removeLinked}
                              disabled={isLive()}
                            />
                          ))}
                          {!isLive() && (
                          <div className="add-icon apmt-add" onClick={() => { add(); addNewAppoinetment(); }}>
                            <PlusIcon />
                            <label>
                              Add a New Appoinment
                            </label>
                          </div>
                          )}
                        </>
                      )}
                    </Form.List>
                  )
                }
                <RadioButton
                  className="procedure-radio"
                  label="Unstructured (Indicate the number of appointments by type.)"
                  checked={form.getFieldValue('programStructureType') === 'unstructured'}
                  value="unstructured"
                  onChange={(e) => onChangeValue('programStructureType', e)}
                  disabled={isLive()}
                />
              </Form.Item>
            </div>
          </div>
        </div>

        {form.getFieldValue('programStructureType') && (
        <div className="program-container__outer program-optin">
          <div className="program-container__wrapper">
            <h1 className="lbl-name-bold">
              Program Pricing
            </h1>
            {
                  (form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both')
                  && ((form.getFieldValue('applicableMemberTypes')?.includes('normal') || form.getFieldValue('applicableMemberTypes')?.includes('dependent'))
                  || (form.getFieldValue('applicableMemberTypes')?.includes('pet') && (form.getFieldValue('petTypes')?.includes('dog') || form.getFieldValue('petTypes')?.includes('cat'))))
                  && (
                    <div className="pgm-procedure-section apmt-border">
                      <div className="apmt-sub-section">
                        <div className="heading">Procedures available in this program:</div>
                        <div className="desc">Select which procedures will be a part of this program.</div>
                      </div>
                      {
        form.getFieldValue('unstructuredProcedures')?.length > 0 && (
        <div className="card">
          {form.getFieldValue('unstructuredProcedures')?.map((provider, pIndex) => (
            <div className="card-item" key={`pro${pIndex}`}>
              <div className="left">
                <h5>{`${_.get(provider, 'procedureName', '')}`}</h5>
              </div>
              {!isLive() && (
              <div className="del-appointment">
                <Space size="middle">
                  <Popconfirm
                    title="Are you sure?"
                    description="Do you want to delete this procedure?"
                    okText="Yes"
                    cancelText="No"
                    onConfirm={() => removeLinked('unstructuredProcedures', pIndex)}
                  >
                    <a>
                      <GreyTrashIcon />
                    </a>
                  </Popconfirm>
                </Space>
              </div>
              )}
            </div>
          ))}
        </div>
        )
                  }
                      <Form.Item
                        name="unstructuredProcedures"
                        className="formfield"
                        rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
                      >
                        <LinkedProcedure
                          showGrid={false}
                          value={form.getFieldValue('unstructuredProcedures') || []}
                          onChange={(v) => {
                            onChangeValue('unstructuredProcedures', [{ ...v }]);
                          }}
                          multiple={false}
                          label=" "
                          tag="Procedure"
                          buttonText="Select Procedure"
                          key={3}
                          showIcon
                          showLabel={false}
                          filter={getProcedureFilters()}
                          disabled={isLive()}
                        />
                      </Form.Item>
                    </div>
                  )
                }

            <div className="program-type-info">
              <h1 className="lbl-name-bold">
                <label>Delivery Methods</label>
              </h1>
            </div>
            <div className="program-calc">
              <div className="sub-program sub-info">
                Estimate how much you will charge per delivery method.
                This estimate will be used to calculate program costs in the final screen.
              </div>
              {
                (form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (
                  <div className="last-section">
                    <div className="lbl-cal-info">
                      <label># of appt</label>
                      <label>$ Delivery Fee</label>
                    </div>
                  </div>
                )
              }

              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>House Call</label>
                </div>
                <div className="qty-wrapper">
                  {
                  (form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (
                    <Form.Item
                      name={['programPricing', 'houseCallQuantity']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowOnlyNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="0"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  )
                  }
                  <div className="amount-text assessment-text">
                    <div className="lbl-name-bold">
                      <label>$</label>
                    </div>
                    <Form.Item
                      name={['programPricing', 'houseCallPrice']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowDecimalNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="00.00"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Luxe Mobile Clinics</label>
                </div>
                <div className="qty-wrapper">
                  {
                  (form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (
                    <Form.Item
                      name={['programPricing', 'luxeMobileClinicsQuantity']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowOnlyNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="0"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  )
                  }
                  <div className="amount-text assessment-text">
                    <div className="lbl-name-bold">
                      <label>$</label>
                    </div>
                    <Form.Item
                      name={['programPricing', 'luxeMobileClinicsPrice']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowDecimalNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="00.00"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Telemedicine</label>
                </div>
                <div className="qty-wrapper">
                  {
                  (form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (
                    <Form.Item
                      name={['programPricing', 'telemedicineQuantity']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowOnlyNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="0"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  )
                  }
                  <div className="amount-text assessment-text">
                    <div className="lbl-name-bold">
                      <label>$</label>
                    </div>
                    <Form.Item
                      name={['programPricing', 'telemedicinePrice']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowDecimalNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="00.00"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Facility</label>
                </div>
                <div className="qty-wrapper">
                  {
                  (form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (
                    <Form.Item
                      name={['programPricing', 'facilityQuantity']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowOnlyNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="0"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  )
                  }
                  <div className="amount-text assessment-text">
                    <div className="lbl-name-bold">
                      <label>$</label>
                    </div>
                    <Form.Item
                      name={['programPricing', 'facilityPrice']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowDecimalNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="00.00"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Messaging</label>
                </div>
                <div className="qty-wrapper">
                  {
                  (form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both') && (
                    <Form.Item
                      name={['programPricing', 'messagingQuantity']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowOnlyNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        placeholder="0"
                        className="formfield"
                        disabled={isLive() || form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('programStructureType') === 'both' || form.getFieldValue('isOnlineOnly') !== true}
                      />
                    </Form.Item>
                  )
                  }
                  <div className="amount-text assessment-text">
                    <div className="lbl-name-bold">
                      <label>$</label>
                    </div>
                    <Form.Item
                      name={['programPricing', 'messagingPrice']}
                      rules={[
                        {
                          required: true,
                          message: 'This field is required',
                        },
                        allowDecimalNumbers,
                      ]}
                    >
                      <InputField
                        type="number"
                        className="formfield"
                        placeholder="00.00"
                        disabled={isLive() || form.getFieldValue('programStructureType') === 'unstructured' || form.getFieldValue('isOnlineOnly') !== true}
                      />
                    </Form.Item>
                  </div>
                </div>
              </div>
              {
  ((form.isFieldTouched('programPricing') || error)
  && invalidPricing
  && (
  <div className="promo-error">
    At least one
    {form.getFieldValue('programStructureType') === 'unstructured' ? ' combination # of appt & Delivery Fee ' : ' of Delivery Fee '}
    {' '}
    should be greater than 0
  </div>
  ))
}
            </div>
            <div className="program-type-info">
              <h1 className="lbl-name-bold">
                <label className="orange">What If Calculator</label>
              </h1>
            </div>
            <div className="program-calc calc-info">
              <div className="sub-program sub-info-revdoc">
                Cancellation fees will not be included in your monthly
                calculation, RevDoc fees apply.
              </div>
              <div className="prg-sub-section">
                <div className="last-section">
                  <div className="lbl-name lbl-amount-name">
                    <label>Cancellation Fee</label>
                  </div>
                  <div className="amount-text assessment-text">
                    <div className="lbl-name-bold">
                      <label>
                        $
                        {form.getFieldValue('cancellationFee') || 0}
                      </label>
                    </div>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-amount-name">
                  <label>Total Assessment Fee</label>
                </div>
                <div className="amount-text assessment-text">
                  <Form.Item name="assessmentFee" noStyle>
                    <InputField type="hidden" />
                  </Form.Item>
                  <div className="lbl-name-bold">
                    <label>
                      $
                      {form.getFieldValue('appointments')?.length > 0 ? form.getFieldValue('assessmentFee') || 0 : 0}
                    </label>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name  lbl-amount-name">
                  <label>Total Delivery Fee</label>
                </div>
                <div className="amount-text assessment-text">
                  <Form.Item name={['programPricing', 'deliveryFee']} noStyle>
                    <InputField type="hidden" />
                  </Form.Item>
                  <div className="lbl-name-bold">
                    <label>
                      $
                      {form.getFieldValue(['programPricing', 'deliveryFee']) || 0}
                    </label>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name  lbl-amount-name">
                  <label>
                    Total Product Price (Pharmaceutical + Material Final Price)
                    + Procedure Price
                  </label>
                </div>
                <div className="amount-text assessment-text">
                  <Form.Item name={['programPricing', 'pharmaceuticalAndMaterialPrice']} noStyle>
                    <InputField type="hidden" />
                  </Form.Item>
                  <div className="lbl-name-bold">
                    <label>
                      $
                      {form.getFieldValue(['programPricing', 'pharmaceuticalAndMaterialPrice']) || 0}
                    </label>
                  </div>
                </div>
              </div>
              <div className="last-section telemedicine-hide">
                <div className="lbl-name  lbl-amount-name">
                  <label>
                    Total At-Home Labs Price
                  </label>
                </div>
                <div className="amount-text assessment-text">
                  <Form.Item name={['programPricing', 'totalAtHomeLabsPrice']} noStyle>
                    <InputField type="hidden" />
                  </Form.Item>
                  <div className="lbl-name-bold">
                    <label>
                      $
                      {form.getFieldValue(['programPricing', 'totalAtHomeLabsPrice']) || 0}
                    </label>
                  </div>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name  lbl-amount-name">
                  <label>Ingredient Inflation Amount</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <label>%</label>
                  </div>
                  <Form.Item name={['programPricing', 'ingredientInflationAmount']} noStyle>
                    <InputField type="hidden" />
                  </Form.Item>
                  <Form.Item
                    name={['programPricing', 'ingredientInflationAmount']}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      allowDecimalNumbers,
                    ]}
                  >
                    <InputField
                      type="number"
                      placeholder="00.00"
                      className="formfield"
                      disabled={isLive()}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
            <div className="program-calc calc-info">
              <div className="last-section">
                <div className="lbl-name lbl-amount-name">
                  <label>Total Program Price</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <Form.Item name={['programPricing', 'totalProgramPrice']} noStyle>
                      <InputField type="hidden" />
                    </Form.Item>
                    <label>
                      $
                      {form.getFieldValue(['programPricing', 'totalProgramPrice']) || 0}
                    </label>
                  </div>
                </div>
              </div>
              <div className="last-section pgm-calc-extra">
                <div className="lbl-name lbl-amount-name">
                  <label>Program Price per month</label>
                </div>
                <div className="amount-text assessment-text">
                  <Form.Item name={['programPricing', 'programPricePerMonth']} noStyle>
                    <InputField type="hidden" />
                  </Form.Item>
                  <div className="lbl-name-bold">
                    <label>
                      $
                      {form.getFieldValue(['programPricing', 'programPricePerMonth']) || 0}
                      <span className="member-label">/mo</span>
                    </label>
                  </div>
                </div>
                <div style={{ width: 30 }}>{isCalcLoading && <Spin className="calc-loader" />}</div>
              </div>
            </div>
            <div className="program-calc calc-info">
              <div className="last-section pgm-calc-extra">
                <div className="lbl-name lbl-amount-name">
                  <label>What percent do you want to mark up this program?</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <label>%</label>
                  </div>
                  <Form.Item
                    name={['programPricing', 'programMarkupPercentage']}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      allowDecimalNumbers,
                    ]}
                  >
                    <InputField
                      type="number"
                      placeholder="00.00"
                      className="formfield"
                      disabled={isLive()}
                    />
                  </Form.Item>
                  <span className="member-label">/mo</span>
                </div>
              </div>
            </div>
            <div className="last-section calc-info pgm-calc-extra">
              <div className="lbl-name lbl-amount-name">
                <label>REV1 mark up price</label>
              </div>
              <div className="amount-text assessment-text">
                <div className="lbl-name-bold">
                  <label>%</label>
                </div>
                <Form.Item
                  name={['programPricing', 'rev1MarkupPercentage']}
                  rules={[
                    {
                      required: true,
                      message: 'This field is required',
                    },
                    allowDecimalNumbers,
                  ]}
                >
                  <InputField
                    type="number"
                    placeholder="00.00"
                    className="formfield"
                    disabled={isLive()}
                  />
                </Form.Item>
                <span className="member-label">/mo</span>
              </div>
            </div>
            <div className="last-section calc-info">
              <div className="lbl-name lbl-amount-name">
                <label>REV1 Program Price</label>
              </div>
              <div className="amount-text assessment-text">
                <Form.Item name="finalBasicMembershipPrice" noStyle>
                  <InputField type="hidden" />
                </Form.Item>

                <div className="lbl-name-bold">
                  <label>
                    $
                    {form.getFieldValue('finalBasicMembershipPrice') || 0}
                    <span className="member-label">/mo</span>
                  </label>
                </div>
              </div>
            </div>
            <div className="last-section calc-info">
              <div className="lbl-name lbl-amount-name">
                <label>REV+ Program Price</label>
              </div>
              <div className="amount-text assessment-text">
                <div className="lbl-name-bold">
                  <Form.Item name="finalPlusMembershipPrice" noStyle>
                    <InputField type="hidden" />
                  </Form.Item>
                  <label>
                    $
                    {form.getFieldValue('finalPlusMembershipPrice') || 0}
                    <span className="member-label">/mo</span>
                  </label>
                </div>
              </div>
            </div>
          </div>
        </div>
        )}

        <div className="program-container__outer program-optin">
          <div className="program-container__wrapper">
            <h1>Opt-in Pricing</h1>
            <div className="opt-in-calc">
              <div className="last-section">
                <h1 className="sub-program">
                  What will you be paying external providers that opt-in to the
                  delivery methods in your program?
                </h1>
              </div>

              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>House Call</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <label>$</label>
                  </div>
                  <Form.Item
                    name={['optInPricing', 'houseCallPrice']}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      allowDecimalNumbers,
                    ]}
                  >
                    <InputField
                      type="number"
                      placeholder="00.00"
                      className="formfield"
                      disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Luxe Mobile Clinics</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <label>$</label>
                  </div>
                  <Form.Item
                    name={['optInPricing', 'luxeMobileClinicsPrice']}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      allowDecimalNumbers,
                    ]}
                  >
                    <InputField
                      type="number"
                      placeholder="00.00"
                      className="formfield"
                      disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Telemedicine</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <label>$</label>
                  </div>
                  <Form.Item
                    name={['optInPricing', 'telemedicinePrice']}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      allowDecimalNumbers,
                    ]}
                  >
                    <InputField
                      type="number"
                      placeholder="00.00"
                      className="formfield"
                      disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Facility</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <label>$</label>
                  </div>
                  <Form.Item
                    name={['optInPricing', 'facilityPrice']}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      allowDecimalNumbers,
                    ]}
                  >
                    <InputField
                      type="number"
                      placeholder="00.00"
                      className="formfield"
                      disabled={isLive() || form.getFieldValue('isOnlineOnly') === true}
                    />
                  </Form.Item>
                </div>
              </div>
              <div className="last-section">
                <div className="lbl-name lbl-name-delivery">
                  <label>Messaging</label>
                </div>
                <div className="amount-text assessment-text">
                  <div className="lbl-name-bold">
                    <label>$</label>
                  </div>
                  <Form.Item
                    name={['optInPricing', 'messagingPrice']}
                    rules={[
                      {
                        required: true,
                        message: 'This field is required',
                      },
                      allowDecimalNumbers,
                    ]}
                  >
                    <InputField
                      type="number"
                      placeholder="00.00"
                      className="formfield"
                      disabled={isLive() || form.getFieldValue('programStructureType') === 'unstructured' || (form.getFieldValue('isOnlineOnly') !== true && form.getFieldValue('programStructureType') !== undefined)}
                    />
                  </Form.Item>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="program-container__outer">
          <div className="action-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            {!(isNew(id) || searchParams.get('clone')) && (
            <div className="updated-date-wrapper">
              <span>Last Updated:</span>
              <span className="orange">{moment(selectedProgram.updatedAt).format('MM/DD/YYYY hh:mm A')}</span>
            </div>
            )}

            <div className="btn-promo-outer">
              <Button loading={isCalcLoading} variant={Button.variant.filled} onClick={() => setGoLive(false)} htmlType="submit">{(isNew(id) || searchParams.get('clone')) ? 'Save' : 'Update'}</Button>
              {((selectedProgram?.status === 'inReview' && selectedProgram?.cancelled !== true) || isNew(id) || searchParams.get('clone')) && (
                <>
                  <Button variant={Button.variant.filled} onClick={() => onShowPopup('preview')}>Preview</Button>
                  <Button className="green-button" onClick={() => onGoLive()} variant={Button.variant.filled}>Go Live</Button>
                </>
              )}
            </div>
          </div>
        </div>
      </Form>
      <DialogPopup
        className="add-preview-dialog"
        open={dialogOpen.isOpen}
        title={dialogOpen?.title}
        handleOk={() => {}}
        handleCancel={onCancelDialogPopup}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        { dialogOpen?.isStaff && (
        <ProgramProviders
          id={id}
          onCancelDialogPopup={onCancelDialogPopup}
        />
        )}
        { dialogOpen?.isPreview && (
        <PreviewProgram
          id={id}
          data={form.getFieldsValue()}
          onCancelDialogPopup={onCancelDialogPopup}
        />
        )}
      </DialogPopup>
    </div>
  );
}
export function BlueTick() {
  return (
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
      <g clipPath="url(#clip0_5757_83465)">
        <path d="M15.5564 7.97343C15.0811 7.48943 14.9751 6.7541 15.2944 6.15543C15.5224 5.72876 15.5384 5.22143 15.3371 4.78076C15.1364 4.34076 14.7417 4.01943 14.2697 3.91276C13.6091 3.76276 13.1217 3.20143 13.0677 2.52543C13.0284 2.04343 12.7671 1.60676 12.3597 1.34543C11.9537 1.08343 11.4484 1.02676 10.9931 1.19276C10.3557 1.4241 9.64239 1.2141 9.23172 0.67543C8.93839 0.29143 8.48239 0.0654297 7.99839 0.0654297C7.51505 0.0654297 7.05905 0.29143 6.76572 0.67543C6.35705 1.21476 5.64372 1.42476 5.00639 1.19343C4.55172 1.0281 4.04639 1.08476 3.63972 1.3461C3.23305 1.60743 2.97105 2.0441 2.93239 2.5261C2.87772 3.2021 2.39105 3.7641 1.73039 3.91343C1.25839 4.0201 0.86372 4.34143 0.663054 4.78143C0.462387 5.22143 0.478387 5.72943 0.706387 6.15543C1.02572 6.75343 0.91972 7.48943 0.445054 7.97343C0.10572 8.3181 -0.0529463 8.80143 0.0157203 9.2801C0.084387 9.75876 0.373054 10.1781 0.79572 10.4134C1.38772 10.7434 1.69705 11.4201 1.55839 12.0834C1.46039 12.5568 1.58772 13.0488 1.90439 13.4141C2.22172 13.7801 2.69105 13.9768 3.17239 13.9461C3.84972 13.9041 4.47439 14.3061 4.71705 14.9388C4.88972 15.3908 5.26372 15.7354 5.72839 15.8714C6.19172 16.0074 6.69305 15.9194 7.08239 15.6334C7.62839 15.2314 8.37305 15.2314 8.91839 15.6334C9.30772 15.9201 9.80905 16.0081 10.2724 15.8714C10.7371 15.7354 11.1111 15.3901 11.2844 14.9388C11.5264 14.3061 12.1517 13.9034 12.8284 13.9461C13.3111 13.9768 13.7797 13.7801 14.0964 13.4141C14.4131 13.0488 14.5411 12.5568 14.4424 12.0834C14.3044 11.4201 14.6131 10.7434 15.2057 10.4134C15.6277 10.1774 15.9157 9.75876 15.9851 9.2801C16.0544 8.80143 15.8951 8.3181 15.5564 7.97343ZM7.16705 11.5934L4.16705 8.6841L5.40505 7.4461L7.16705 9.11676L10.9291 5.26076L12.1671 6.49876L7.16705 11.5934Z" fill="#17A1FA" />
      </g>
      <defs>
        <clipPath id="clip0_5757_83465">
          <rect width="16" height="16" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
