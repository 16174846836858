import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import Button from 'shared/components/button/Button';
import DialogPopup from 'shared/components/dialogPopup/DialogPopup';
import { AddOnList } from './AddOnList';
import './addon.scss';

export function AddOns(props) {
  const {
    id, selectedAddOns, setSelectedAddOns, filter,
  } = props;
  const [dialogOpen, setDialogOpen] = useState(false);
  const [addOns, setAddOns] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const { proceduresData } = useSelector((state) => state.proceduresSetup);

  const onSelectingProcedure = (data, flag) => {
    const items = addOns;
    const index = items?.findIndex((x) => x === data?.id);
    if (flag && index === -1) {
      items.push(data?.id);
    } else if (index !== -1) {
      items.splice(index, 1);
    }
    setAddOns(items);
    setRefresh(!refresh);
  };

  const onRemoveItem = (data, flag) => {
    onSelectingProcedure(data, flag);
    setSelectedAddOns(addOns);
  };

  useEffect(() => {
    setAddOns(JSON.parse(JSON.stringify(selectedAddOns)));
  }, [selectedAddOns]);

  const onClosePopup = () => {
    setAddOns([]);
    setDialogOpen(false);
    setRefresh(!refresh);
  };

  return (
    <div className="addon">
      <span>
        <div>Add Add-On</div>
      </span>
      <div>
        <Button
          variant={Button.variant.filled}
          onClick={() => { setDialogOpen(true); }}
          className="addon__button"
        >
          Add Add-on
        </Button>
      </div>
      {selectedAddOns?.length > 0 && (
      <>
        <span>
          <div>Added Add-On</div>
        </span>
        <AddOnList filter={filter} key="cview" id="1" pid={id} mode="view" data={selectedAddOns} selectedItems={[]} onSelection={onRemoveItem} />
      </>
      )}
      <DialogPopup
        className="add-bio-marker-dialog"
        open={dialogOpen}
        title="Select Add On"
        handleOk={() => {}}
        handleCancel={() => onClosePopup()}
        cancelBtn=""
        showActionButton={false}
        saveBtn=""
      >
        <AddOnList filter={filter} key="edit" id="2" pid={id} mode="edit" data={[]} selectedItems={addOns} onSelection={onSelectingProcedure} />
        {(proceduresData.docs?.length === 0 || proceduresData.docs?.length === undefined)
          && <div className="no-record"> No Data  </div> }
        <Button
          variant={Button.variant.filled}
          onClick={() => { setSelectedAddOns(addOns); onClosePopup(); }}
          className="addon__saveaddon"
        >
          Add Selected Add Ons
        </Button>
      </DialogPopup>
    </div>
  );
}
