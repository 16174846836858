import React from 'react';
import {
  BrowserRouter,
  Routes,
  Route,
  Navigate,
} from 'react-router-dom';
import Login from 'pages/authentication/login/Login';
import PasswordReset from 'pages/authentication/passwordReset/PasswordReset';
import { URLS } from 'routes';
// import SignUp from 'pages/authentication/signup/SignUp';
import AppLayout from 'shared/layouts/AppLayout';
import Dashboard from 'pages/dashboard/Dashboard';
import ProcedureManagement from 'pages/dashboard/components/procedureManagement/ProcedureManagement';
import CategorySetupList from 'pages/dashboard/components/procedureManagement/components/categorySetupList/CategorySetupList';
import ProcedureSetupList from 'pages/dashboard/components/procedureManagement/components/procedureSetupList/ProcedureSetupList';
import SuppliesSetupList from 'pages/dashboard/components/procedureManagement/components/suppliesSetupList/SuppliesSetupList';
import AddNewCategorySetup from 'pages/dashboard/components/procedureManagement/components/addCategorySetup/AddNewCategory';
import AddNewSuppliesSetup from 'pages/dashboard/components/procedureManagement/components/addSuppliesSetup/AddNewSupply';
import AddNewProcedure from 'pages/dashboard/components/procedureManagement/components/addProcedureSetup/AddNewProcedure';
import InsurancePlan from 'pages/dashboard/components/insurancePlan/InsurancePlan';
import InsurancePlansList from 'pages/dashboard/components/insurancePlan/components/insurancePlansList/InsurancePlansList';
import AddNewInsurancePlan from 'pages/dashboard/components/insurancePlan/components/addInsurancePlan/AddNewInsurancePlan';
import PrmDashboard from 'pages/dashboard/components/prmDashboard/PrmDashboard';
import PrmDashboardStatistics from 'pages/dashboard/components/prmDashboard/components/prmDashboardStatistics/PrmDashboardStatistics';
import ProfileSetup from 'pages/dashboard/components/prmDashboard/components/profileSetup/ProfileSetup';
import ProviderList from 'pages/dashboard/components/prmDashboard/components/providerList/ProviderList';
import Providers from 'pages/dashboard/components/prmDashboard/components/providerDetail/Providers';
import ComplaintsList from 'pages/dashboard/components/prmDashboard/components/complaints/ComplaintsList';
import UserManagement from 'pages/dashboard/components/userManagement/userManagement';
import AddNewUser from 'pages/dashboard/components/userManagement/components/addUser/addNewUser';
import Promo from 'pages/dashboard/components/promos/Promo';
import AddNewPromo from 'pages/dashboard/components/promos/AddNewPromo';
import Specialty from 'pages/dashboard/components/specialty/Specialty';
import AddNewSpecialty from 'pages/dashboard/components/specialty/AddNewSpecialty';
import ComplaintDetail from 'pages/dashboard/components/prmDashboard/components/complaints/ComplainDetail';
import Symptoms from 'pages/dashboard/components/symptoms/Symptoms';
import AssignedProvidersList from 'pages/dashboard/components/userManagement/components/assignedProvidersList/AssignedProvidersList';
import SymptomsList from 'pages/dashboard/components/symptoms/components/syptomsList';
import AddNewAtHomeLab from 'pages/dashboard/components/procedureManagement/components/addAtHomeLab/AddNewAtHomeLab';
import AtHomeLabList from 'pages/dashboard/components/procedureManagement/components/atHomeLabList/AtHomeLabList';
import AddNewSymptom from 'pages/dashboard/components/symptoms/components/addNewSymptom';
import Content from 'pages/dashboard/components/content/Content';
import MemberFAQ from 'pages/dashboard/components/content/components/member/memberFAQ';
import AddNewMemberFAQ from 'pages/dashboard/components/content/components/member/addNewMemberFAQ';
import MemberTermCondition from 'pages/dashboard/components/content/components/member/memberTermCondition';
import AddNewMemberTermCondition from 'pages/dashboard/components/content/components/member/addNewTermCondition';
import MemberVideo from 'pages/dashboard/components/content/components/member/memberVideo';
import AddNewMemberVideo from 'pages/dashboard/components/content/components/member/addNewMemberVideo';
import ProviderFAQ from 'pages/dashboard/components/content/components/provider/providerFAQ';
import AddNewProviderFAQ from 'pages/dashboard/components/content/components/provider/addNewProviderFAQ';
import ProviderTermCondition from 'pages/dashboard/components/content/components/provider/providerrTermCondition';
import AddNewProviderTermCondition from 'pages/dashboard/components/content/components/provider/addNewTermCondition';
import ProviderVideo from 'pages/dashboard/components/content/components/provider/providerVideo';
import AddNewProviderVideo from 'pages/dashboard/components/content/components/provider/addNewProviderVideo';
import PrivacyPolicy from 'pages/dashboard/components/content/components/privacyPolicy/privacyPolicy';
import AddNewPrivacyPolicy from 'pages/dashboard/components/content/components/privacyPolicy/addNewPrivacyPolicy';
import BioMarkerList from 'pages/dashboard/components/procedureManagement/components/bioMarkerList/BioMarkerList';
import AddNewBiomarker from 'pages/dashboard/components/procedureManagement/components/addBiomarker/AddNewBiomarker';
import MedicationSetList from 'pages/dashboard/components/procedureManagement/components/medicationSetList/MedicationSetList';
import AddNewMedicationSet from 'pages/dashboard/components/procedureManagement/components/addMedicationSets/AddNewMedicationSet';
import QuickLinks from 'pages/dashboard/components/procedureManagement/components/quickLinks/QuickLinks';
import Suggestion from 'pages/dashboard/components/suggestion/Suggestion';
import MemberFeedbackList from 'pages/dashboard/components/suggestion/components/memberfeedback/MemberFeedbackList';
import MemberFeedbackDetail from 'pages/dashboard/components/suggestion/components/memberfeedback/MemberFeedbackDetail';
import ProviderFeedbackDetail from 'pages/dashboard/components/suggestion/components/providerfeedback/ProviderFeedbackDetail';
import ProviderFeedbackList from 'pages/dashboard/components/suggestion/components/providerfeedback/ProviderFeedbackList';
import HowToPrepare from 'pages/dashboard/components/content/components/howToPrepare/HowToPrepare';
import Programs from 'pages/dashboard/components/programs/Programs';
import AddNewProgram from 'pages/dashboard/components/programs/AddNewProgram';
import WelcomeMessage from 'pages/dashboard/components/welcomMessage/WelcomeMessage';
import ProgramMembers from 'pages/dashboard/components/programs/ProgramMembers';
import AddNewMemberProgram from 'pages/dashboard/components/programs/AddNewMemebr';
import Affiliation from 'pages/dashboard/components/affiliation/Affiliations';
import AddNewAffiliation from 'pages/dashboard/components/affiliation/AddAffiliation';
import AddNewGiveaway from 'pages/dashboard/components/giveaway/AddGiveaway';
import Giveaways from 'pages/dashboard/components/giveaway/Giveaways';
import OtpVerify from 'pages/authentication/verify/OtpVerify';
import AddProviderState from 'pages/dashboard/components/providerState/AddProviderState';
import ProviderStateList from 'pages/dashboard/components/providerState/ProviderStates';
import GiveawaysDashboard from 'pages/dashboard/components/giveaway/GiveawaysDashboard';
import Criteria from 'pages/dashboard/components/giveaway/Criteria';
import AddNewCriteria from 'pages/dashboard/components/giveaway/AddCriteria';
import ProgramCancellationPolicy from 'pages/dashboard/components/content/components/programCancellationPolicy/programCancellationPolicy';
import AddProgramCancellationPolicy from 'pages/dashboard/components/content/components/programCancellationPolicy/addNewProgramCancellationPolicy';
import HomeConfig from 'pages/dashboard/components/homePageTop/HomeConfig';
import MemberInviteContent from 'pages/dashboard/components/content/components/memberInviteContent/memberInviteContent';
import AddMemberInviteContent from 'pages/dashboard/components/content/components/memberInviteContent/addNewMemberInviteContent';
import HomePageTopDashboard from 'pages/dashboard/components/homePageTop/HomePageTopDashboard';
import RevGives from 'pages/dashboard/components/revGives/RevGives';
import AddNewRevGives from 'pages/dashboard/components/revGives/AddRevGives';
import VerifyAccess from './components/verifyAccess/verifyAccess';
import VerifyLoggedIn from './components/verifyLoggedIn/VerifyLoggedIn';

export default function AppRoutes() {
  return (
    <BrowserRouter>
      <Routes>
        <Route element={(<AppLayout />)}>
          <Route index element={<Navigate to={URLS.LOGIN_URL} />} />
          <Route
            path={URLS.LOGIN_URL}
            element={(
              <VerifyLoggedIn>
                <Login />
              </VerifyLoggedIn>
            )}
          />
          {/* <Route
            path={URLS.SIGNUP_URL}
            element={(
              <VerifyLoggedIn>
                <SignUp />
              </VerifyLoggedIn>
            )}
          /> */}
          <Route
            path={URLS.RESET_PASSWORD_URL}
            element={(
              <VerifyLoggedIn>
                <PasswordReset />
              </VerifyLoggedIn>
            )}
          />
          <Route
            path={URLS.OTP_URL}
            element={(
              <VerifyLoggedIn>
                <OtpVerify />
              </VerifyLoggedIn>
            )}
          />
          <Route
            path={URLS.DASHBOARD_URL}
            element={(
              <VerifyLoggedIn>
                <Dashboard />
              </VerifyLoggedIn>
            )}
          >
            {/* <Route index element={<Navigate to={URLS.CATEGORY_SETUP_URL} />} /> */}
            <Route
              path={URLS.INSURANCE_PLAN_URL}
              element={(
                <VerifyAccess name="insurancePlans">
                  <InsurancePlan />
                </VerifyAccess>
              )}
            >
              <Route index element={<Navigate to={URLS.INSURANCE_PLANS_URL} />} />
              <Route
                path={URLS.INSURANCE_PLANS_URL}
                Component={InsurancePlansList}
              />
              <Route
                path={URLS.INSURANCE_PLAN_FORM_URL}
                Component={AddNewInsurancePlan}
              />
            </Route>

            <Route
              path={URLS.SPECIALTY_URL}
              element={(
                <VerifyAccess name="specialistSetup">
                  <Specialty />
                </VerifyAccess>
              )}
            />
            <Route
              path={URLS.SPECIALITY_FORM_URL}
              element={(
                <VerifyAccess name="specialistSetup">
                  <AddNewSpecialty />
                </VerifyAccess>
              )}
            />
            <Route
              path={URLS.WELCOM_MESSAGE_URL}
              element={(
                <VerifyAccess name="welcomeMessageSetup">
                  <WelcomeMessage />
                </VerifyAccess>
              )}
            />

            <Route
              path={URLS.MEMBER_HOME_SCREEN_URL}
              Component={HomePageTopDashboard}
            >
              <Route index element={<Navigate to={URLS.MEMBER_HOME1_SCREEN_URL} />} />
              <Route
                path={URLS.MEMBER_HOME1_SCREEN_URL}
                element={(
                  <VerifyAccess name="memberHomeScreenSetup">
                    <HomeConfig type="normal" />
                  </VerifyAccess>
              )}
              />
              <Route
                path={URLS.MEMBER_HOME2_SCREEN_URL}
                element={(
                  <VerifyAccess name="memberHomeScreenSetup">
                    <HomeConfig type="dependent" />
                  </VerifyAccess>
              )}
              />
              <Route
                path={URLS.MEMBER_HOME3_SCREEN_URL}
                element={(
                  <VerifyAccess name="memberHomeScreenSetup">
                    <HomeConfig type="pet" petType="cat" />
                  </VerifyAccess>
              )}
              />
              <Route
                path={URLS.MEMBER_HOME4_SCREEN_URL}
                element={(
                  <VerifyAccess name="memberHomeScreenSetup">
                    <HomeConfig type="pet" petType="dog" />
                  </VerifyAccess>
              )}
              />
            </Route>

            <Route
              path={URLS.SYMPTOM_URL}
              Component={Symptoms}
            >
              <Route index element={<Navigate to={URLS.SYMPTOMS_URL} />} />
              <Route
                path={URLS.SYMPTOMS_URL}
                Component={SymptomsList}
              />
              <Route
                path={URLS.SYMPTOMS_FORM_URL}
                Component={AddNewSymptom}
              />
            </Route>

            <Route
              path={URLS.USER_URL}
            >
              <Route
                path={URLS.USERS_URL}
                element={(
                  <VerifyAccess name="userManagement">
                    <UserManagement />
                  </VerifyAccess>
                )}
              />

              <Route
                path={URLS.USERS_FORM_URL}
                element={(
                  <VerifyAccess name="userManagement">
                    <AddNewUser />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.USERS_PROVIDERS_URL}
                element={(
                  <VerifyAccess name="myProviders">
                    <AssignedProvidersList />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.PROMO_URL}
            >
              <Route
                path={URLS.PROMOS_URL}
                element={(
                  <VerifyAccess name="promotionSetup">
                    <Promo />
                  </VerifyAccess>
                )}
              />

              <Route
                path={URLS.PROMOS_FORM_URL}
                element={(
                  <VerifyAccess name="promotionSetup">
                    <AddNewPromo />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.PROGRAM_URL}
            >
              <Route
                path={URLS.PROGRAMS_URL}
                element={(
                  <VerifyAccess name="programSetup">
                    <Programs />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.PROGRAM_MEMBER_URL}
                element={(
                  <VerifyAccess name="programSetup">
                    <ProgramMembers />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.PROGRAM_FORM_URL}
                element={(
                  <VerifyAccess name="programSetup">
                    <AddNewProgram />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.PROGRAM_MEMBER_FORM_URL}
                element={(
                  <VerifyAccess name="programSetup">
                    <AddNewMemberProgram />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.AFFILIATION_URL}
            >
              <Route
                path={URLS.AFFILIATIONS_URL}
                element={(
                  <VerifyAccess name="affiliationSetup">
                    <Affiliation />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.AFFILIATION_FORM_URL}
                element={(
                  <VerifyAccess name="affiliationSetup">
                    <AddNewAffiliation />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.GIVEAWAY_URL}
              Component={GiveawaysDashboard}
            >
              <Route index element={<Navigate to={URLS.GIVEAWAYS_URL} />} />
              <Route
                path={URLS.GIVEAWAYS_URL}
                element={(
                  <VerifyAccess name="giveawaySetup">
                    <Giveaways />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.GIVEAWAYS_FORM_URL}
                element={(
                  <VerifyAccess name="giveawaySetup">
                    <AddNewGiveaway />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.GIVEAWAYS_CRITERIA_URL}
                element={(
                  <VerifyAccess name="giveawayCriteriaSetup">
                    <Criteria />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.GIVEAWAYS_CRITERIA_FORM_URL}
                element={(
                  <VerifyAccess name="giveawayCriteriaSetup">
                    <AddNewCriteria />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.PROCEDURE_MGMT_URL}
              element={(
                <ProcedureManagement />
              )}
            >
              <Route index element={<Navigate to={URLS.CATEGORY_SETUP_URL} />} />
              <Route
                path={URLS.CATEGORY_SETUP_URL}
                element={(
                  <VerifyAccess name="categorySetup">
                    <CategorySetupList />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.CATEGORY_SETUP_FORM_URL}
                element={(
                  <VerifyAccess name="categorySetup">
                    <AddNewCategorySetup />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.SUPPLIES_SETUP_URL}
                element={(
                  <VerifyAccess name="suppliesSetup">
                    <SuppliesSetupList />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.SUPPLIES_SETUP_FORM_URL}
                element={(
                  <VerifyAccess name="suppliesSetup">
                    <AddNewSuppliesSetup />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.AT_HOME_LABS_URL}
                element={(
                  // <VerifyAccess name="suppliesSetup">
                  <AtHomeLabList />
                  // </VerifyAccess>
                )}
              />
              <Route
                path={URLS.AT_HOME_LABS_FORM_URL}
                element={(
                  // <VerifyAccess name="suppliesSetup">
                  <AddNewAtHomeLab />
                  // </VerifyAccess>
                )}
              />
              <Route
                path={URLS.BIOMARKER_URL}
                Component={BioMarkerList}
              />
              <Route
                path={URLS.BIOMARKER_FORM_URL}
                Component={AddNewBiomarker}
              />
              <Route
                path={URLS.MEDICATION_SETS_URL}
                Component={MedicationSetList}
              />
              <Route
                path={URLS.MEDICATION_SETS_FORM_URL}
                Component={AddNewMedicationSet}
              />
              <Route
                path={URLS.QUICKLINKS_URL}
                Component={QuickLinks}
              />
              <Route
                path={URLS.PROCEDURE_SETUP_URL}
                element={(
                  <VerifyAccess name="procedureSetup">
                    <ProcedureSetupList />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.PROCEDURE_SETUP_FORM_URL}
                element={(
                  <VerifyAccess name="procedureSetup">
                    <AddNewProcedure />
                  </VerifyAccess>
                )}
              />
            </Route>

            <Route
              path={URLS.PRM_DASHBOARD_URL}
              Component={PrmDashboard}
            >
              <Route index element={<Navigate to={URLS.PROFILE_SETUP_URL} />} />
              <Route
                path={URLS.PRM_DASHBOARD_STATS_URL}
                Component={PrmDashboardStatistics}
              />

              <Route
                path={URLS.PROFILE_SETUP_URL}
                element={(
                  <VerifyAccess name="profileSetup">
                    <ProfileSetup />
                  </VerifyAccess>
                )}
              />

              <Route
                path={URLS.MY_PROVIDERS_URL}
                element={(
                  <VerifyAccess name="myProviders">
                    <ProviderList />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.MY_PROVIDERS_DETAIL_URL}
                element={(
                  <VerifyAccess name="myProviders">
                    <Providers />
                  </VerifyAccess>
                )}
              />

              <Route
                path={URLS.COMPLAINTS_URL}
                element={(
                  <VerifyAccess name="myProviders">
                    <ComplaintsList />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.COMPLAINTS_DETAIL_URL}
                element={(
                  <VerifyAccess name="complaints">
                    <ComplaintDetail />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.CONTENT_DASHBOARD_URL}
              Component={Content}
            >
              <Route index element={<Navigate to={URLS.MEMBER_FAQ_URL} />} />
              <Route
                path={URLS.MEMBER_FAQ_URL}
                Component={MemberFAQ}
              />
              <Route
                path={URLS.MEMBER_FAQ_FORM_URL}
                Component={AddNewMemberFAQ}
              />
              <Route
                path={URLS.MEMBER_TERM_URL}
                Component={MemberTermCondition}
              />
              <Route
                path={URLS.MEMBER_TERM_FORM_URL}
                Component={AddNewMemberTermCondition}
              />
              <Route
                path={URLS.MEMBER_VIDEO_URL}
                Component={MemberVideo}
              />
              <Route
                path={URLS.MEMBER_VIDEO_FORM_URL}
                Component={AddNewMemberVideo}
              />
              <Route
                path={URLS.PROVIDER_FAQ_URL}
                Component={ProviderFAQ}
              />
              <Route
                path={URLS.PROVIDER_FAQ_FORM_URL}
                Component={AddNewProviderFAQ}
              />
              <Route
                path={URLS.PROVIDER_TERM_URL}
                Component={ProviderTermCondition}
              />
              <Route
                path={URLS.PROVIDER_TERM_FORM_URL}
                Component={AddNewProviderTermCondition}
              />
              <Route
                path={URLS.PROVIDER_VIDEO_URL}
                Component={ProviderVideo}
              />
              <Route
                path={URLS.PROVIDER_VIDEO_FORM_URL}
                Component={AddNewProviderVideo}
              />
              <Route
                path={URLS.PRIVACY_POLICY_URL}
                Component={PrivacyPolicy}
              />
              <Route
                path={URLS.PRIVACY_POLICY_FORM_URL}
                Component={AddNewPrivacyPolicy}
              />
              <Route
                path={URLS.PROGRAM_CANCELLATION_POLICY_URL}
                Component={ProgramCancellationPolicy}
              />
              <Route
                path={URLS.PROGRAM_CANCELLATION_POLICY_FORM_URL}
                Component={AddProgramCancellationPolicy}
              />
              <Route
                path={URLS.MEMBER_INVITE_CONTENT_URL}
                Component={MemberInviteContent}
              />
              <Route
                path={URLS.MEMBER_INVITE_CONTENT_FORM_URL}
                Component={AddMemberInviteContent}
              />
              <Route
                path={URLS.HOW_TO_PREPARE_URL}
                Component={HowToPrepare}
              />
            </Route>
            <Route
              path={URLS.SUGGESTION_DASHBOARD_URL}
              Component={Suggestion}
            >
              <Route index element={<Navigate to={URLS.SUGGESTION_MEMBER_URL} />} />
              <Route
                path={URLS.SUGGESTION_MEMBER_URL}
                Component={MemberFeedbackList}
              />
              <Route
                path={URLS.SUGGESTION_PROVIDER_URL}
                Component={ProviderFeedbackList}
              />
              <Route
                path={URLS.SUGGESTION_DETAIL_MEMBER_URL}
                element={(
                  <VerifyAccess name="memberFaq">
                    <MemberFeedbackDetail />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.SUGGESTION_DETAIL_PROVIDER_URL}
                element={(
                  <VerifyAccess name="memberFaq">
                    <ProviderFeedbackDetail />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.PROVIDER_STATE_URL}
            >
              <Route
                path={URLS.PROVIDER_STATES_URL}
                element={(
                  <VerifyAccess name="providerDefaultSetup">
                    <ProviderStateList />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.PROVIDER_STATES_FORM_URL}
                element={(
                  <VerifyAccess name="providerDefaultSetup">
                    <AddProviderState />
                  </VerifyAccess>
                )}
              />
            </Route>
            <Route
              path={URLS.REV_GIVE_URL}
            >
              <Route
                path={URLS.REV_GIVES_URL}
                element={(
                  <VerifyAccess name="isRevGivesSetup">
                    <RevGives />
                  </VerifyAccess>
                )}
              />
              <Route
                path={URLS.REV_GIVES_FORM_URL}
                element={(
                  <VerifyAccess name="isRevGivesSetup">
                    <AddNewRevGives />
                  </VerifyAccess>
                )}
              />
            </Route>
          </Route>
        </Route>
      </Routes>
    </BrowserRouter>
  );
}
