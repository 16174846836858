/* eslint-disable no-unreachable */
import { Checkbox, Form } from 'antd';
import _ from 'lodash';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { createUserManagementSetup, getUserManagementSetup, updateUserManagementSetup } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { clearData } from 'redux-store/userManagementSetup/userManagementSetupSlice';
import Button from 'shared/components/button/Button';
import CustomDatePicker from 'shared/components/datePicker/DatePicker';
import InputWithLabel from 'shared/components/inputWithLabel/InputWithLabel';
import StateCountiesDropDown from 'shared/components/stateCountiesDropDown/StateCountiesDropDown';
import UsersSearchInput from 'shared/components/usersSearchInput/UsersSearchInput';
import { isNew } from 'shared/utils/global';
import { applyTrim, blackListedChars, emptyFieldValidator } from 'shared/utils/validators';
import '../../_userManagement.scss';

function AddNewUser() {
  const { id } = useParams();
  // eslint-disable-next-line no-unused-vars
  const [, forceUpdate] = useState(0);
  const { selectedUserManagementSetup } = useSelector((state) => state.userManagementSetups);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [form] = Form.useForm();

  const goBack = () => {
    form.resetFields();
    navigate(-1);
  };
  const handleSubmit = async (vals) => {
    vals = applyTrim(vals);
    vals.counties = _.union(...vals.counties.map(({ counties }) => counties));
    if (id === 'new') {
      dispatch(createUserManagementSetup({
        ...vals,
        // profileAbout: ' ',
      })).then((payload) => {
        if (_.isEmpty(payload.error)) {
          goBack();
        }
      });
    } else {
      dispatch(updateUserManagementSetup({ id, params: vals }));
    }
  };
  useEffect(() => {
    if (id !== 'new') {
      dispatch(getUserManagementSetup(id));
    }
    return () => dispatch(clearData());
  }, []);

  useEffect(() => {
    if (!_.isEmpty(selectedUserManagementSetup)) {
      form.setFieldsValue({
        ...selectedUserManagementSetup,
        counties: _.get(selectedUserManagementSetup, 'counties', []).reduce((acc, o) => {
          const stateFound = acc.find(({ stateCode }) => o.stateCode === stateCode);
          if (stateFound) {
            stateFound.counties.push(o.id);
          } else {
            acc.push({ stateCode: o.stateCode, counties: [o.id] });
          }
          return acc;
        }, []),
        reportsTo: _.get(selectedUserManagementSetup, 'reportsTo.id'),
      });
    } else {
      form.setFieldsValue({ counties: [{}] });
    }
  }, [selectedUserManagementSetup]);

  return (
    <div className="user-management-container__add-new-user-container">
      <div className="user-management-container__add-new-user-container--main-wrapper">
        <div className="heading">{id === 'new' ? 'Add New User' : 'Update User'}</div>
        <Form
          onFinish={handleSubmit}
          form={form}
          onValuesChange={(v) => {
            forceUpdate((n) => !n);
            if (v.isPrm) {
              form.setFieldsValue({
                accessFields: {
                  userManagement: false,
                  categorySetup: false,
                  procedureSetup: false,
                  insurancePlans: false,
                  suppliesSetup: false,
                  specialistSetup: false,
                  labs: false,
                  symptoms: false,
                  providerFaq: false,
                  providerTerms: false,
                  providerHowTos: false,
                  memberFaq: false,
                  memberTerms: false,
                  memberHowTos: false,
                  privacyPolicy: false,
                  biomarkerSetup: false,
                  howToPrepare: false,
                  medicationSetSetup: false,
                  memberFeedback: false,
                  providerFeedback: false,
                  quicklinksSetup: false,
                  promotionSetup: false,
                  programSetup: false,
                  memberHomeScreenSetup: false,
                  welcomeMessageSetup: false,
                  affiliationSetup: false,
                  giveawaySetup: false,
                },
              });
            }
          }}
          onFinishFailed={() => toast.error('Please fill the entire form')}

        >
          <div className="input-field-wrapper">
            <label htmlFor="name" className="user-label">Name</label>
            <div className="d-grid">
              <Form.Item
                name="firstName"
                className="formfield"
                rules={[
                  { validator: emptyFieldValidator, message: 'This field is required' },
                  blackListedChars,
                ]}
              >
                <InputWithLabel label="First Name *" />
              </Form.Item>
              <Form.Item
                name="middleName"
                className="formfield"
                rules={[
                  blackListedChars,
                ]}
              >
                <InputWithLabel label="M.I." />
              </Form.Item>
              <Form.Item
                name="lastName"
                className="formfield"
                rules={[
                  { validator: emptyFieldValidator, message: 'This field is required' },
                  blackListedChars,
                ]}
              >
                <InputWithLabel label="Last Name *" />
              </Form.Item>
            </div>
          </div>
          <div className="border-line" />
          <div className="input-field-wrapper">
            <div>
              <div className="user-label">Username</div>
              <div className="user-sub-label">RevDoc email address</div>
            </div>
            <Form.Item
              name="revdocEmail"
              className="formfield"
              rules={[
                // { validator: emptyFieldValidator, message: 'This field is required' },
                { type: 'email', message: 'This field is not a valid email' },
                blackListedChars,
              ]}
            >
              <InputWithLabel disabled={!isNew(id)} label="RevDoc Email Address *" />
            </Form.Item>
          </div>
          <div className="border-line" />
          <div className="input-field-wrapper">
            <div className="user-label">Contact Information</div>
            <div className="contact-d-grid">
              <Form.Item
                name="personalEmail"
                className="formfield"
                rules={[
                  { validator: emptyFieldValidator, message: 'This field is required' },
                  { type: 'email', message: 'This field is not a valid email' },
                  blackListedChars,
                ]}
              >
                <InputWithLabel label="Personal Email Address *" />
              </Form.Item>
              <Form.Item
                name="mobilePhoneNumber"
                className="formfield"
                rules={[
                  { validator: emptyFieldValidator, message: 'This field should atleast have 9 characters long' },
                  { pattern: /^[\d]{9,13}$/, message: 'This field should have a minimum of 9 characters and maximum of 13 characters' },
                ]}
              >
                <InputWithLabel label="Mobile Number *" minLength={9} maxL />
              </Form.Item>
            </div>
          </div>
          <div className="border-line" />

          <div className="input-field-wrapper">
            <div className="user-label">Reports To</div>
            <Form.Item
              name="reportsTo"
              className="formfield"
              rules={[
                { validator: form.getFieldValue('isPrm') ? emptyFieldValidator : undefined, message: 'This field is required' },
              ]}
            >
              <UsersSearchInput placeholder={`Reports To ${form.getFieldValue('isPrm') ? '*' : ''}`} />
            </Form.Item>
          </div>
          <div className="border-line" />

          <div className="input-field-wrapper">
            <div className="user-label">Start Date</div>
            <Form.Item
              name="startDate"
              className="formfield"
              rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              getValueProps={(i) => ({ value: i && moment(i) })}
            >
              <CustomDatePicker placeholder="Start Date *" />
            </Form.Item>
          </div>
          <div className="border-line" />

          <div className="input-field-wrapper">
            <div className="user-label">Term Date</div>
            <Form.Item
              name="endDate"
              className="formfield"
              // rules={[{ validator: emptyFieldValidator, message: 'This field is required' }]}
              getValueProps={(i) => ({ value: i && moment(i) })}

            >
              <CustomDatePicker
                disabledDate={(d) => moment(d).isBefore(form.getFieldValue('startDate'))}
              />
            </Form.Item>
          </div>
          <div className="border-line" />

          <div className="input-field-wrapper">
            <div className="user-label">Is a PRM user </div>
            <Form.Item
              name="isPrm"
              className="formfield"
              valuePropName="checked"
            >
              <Checkbox />
            </Form.Item>
          </div>
          <div className="border-line" />

          <div className="input-field-wrapper">
            <div className="user-label">State/County Assigned To</div>
            <div className="contact-d-grid">
              <Form.Item
                name="counties"
                className="formfield"
                rules={[
                  { validator: form.getFieldValue('isPrm') ? emptyFieldValidator : () => Promise.resolve(), message: 'This field is required' },
                  {
                    message: 'This Field is required',
                    validator: form.getFieldValue('isPrm') ? (m, val) => {
                      if (val && val.find((x) => !x.counties?.length)) {
                        return Promise.reject(m);
                      }
                      return Promise.resolve(m);
                    }
                      : () => Promise.resolve(),
                  },
                ]}
              >
                <StateCountiesDropDown
                  placeholder={`Select Counties ${form.getFieldValue('isPrm') ? '*' : ''}`}
                />
              </Form.Item>
            </div>
          </div>
          <div className="border-line" />

          <div className="input-field-wrapper">
            <div className="user-label">User Access</div>
            <div className="contact-d-grid">
              <Form.Item
                name={['accessFields', 'profileSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox>Profile Setup</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'complaints']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox>Complaints</Checkbox>
              </Form.Item>

              <Form.Item
                name={['accessFields', 'myProviders']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox>My Providers</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'userManagement']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>User Management</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'categorySetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Category Setup</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'procedureSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Procedure Setup</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'insurancePlans']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Insurance Plans</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'suppliesSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Supplies Setup</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'specialistSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Specialist Setup</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'labs']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>At Home Labs</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'symptoms']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Symptoms</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'providerFaq']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Provider FAQ</Checkbox>
              </Form.Item>

              <Form.Item
                name={['accessFields', 'providerTerms']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Provider Terms</Checkbox>
              </Form.Item>

              <Form.Item
                name={['accessFields', 'providerHowTos']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Provider How To Videos</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'memberFaq']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Member FAQ</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'memberTerms']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Member Terms</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'memberHowTos']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Member How To Videos</Checkbox>
              </Form.Item>

              <Form.Item
                name={['accessFields', 'privacyPolicy']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Privacy Policy</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'memberInviteContent']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Member Invite</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'programCancelationPolicy']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Program Cancellation Policy</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'biomarkerSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Biomarker Set Up</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'howToPrepare']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>How To Prepare</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'medicationSetSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Medication Sets Set Up</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'memberFeedback']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Member Feedback</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'providerFeedback']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Provider Feedback</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'quicklinksSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Quick Links Set Up</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'promotionSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Promo Codes</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'programSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Programs</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'memberHomeScreenSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Member Home Screen</Checkbox>
              </Form.Item>
              {/* <Form.Item
                name={['accessFields', 'memberHomeTopSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Member Home Top</Checkbox>
              </Form.Item> */}
              <Form.Item
                name={['accessFields', 'welcomeMessageSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Welcome Message</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'affiliationSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Affiliations</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'giveawaySetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Giveaways</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'giveawayCriteriaSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Giveaway Criteria</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'providerDefaultSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>Provider</Checkbox>
              </Form.Item>
              <Form.Item
                name={['accessFields', 'isRevGivesSetup']}
                className="formfield"
                valuePropName="checked"
              >
                <Checkbox disabled={form.getFieldValue('isPrm')}>RevGives</Checkbox>
              </Form.Item>
            </div>
          </div>
          <div className="border-line" />
          <div className="add-new-user-action-btn-wrapper">
            <Button variant={Button.variant.outlined} onClick={goBack}>Cancel</Button>
            <Button variant={Button.variant.filled} htmlType="submit">Set Up</Button>
          </div>
        </Form>
      </div>
    </div>
  );
}

export default AddNewUser;
