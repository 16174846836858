/* eslint-disable max-len */
import React, { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import NoAccess from 'shared/components/noAccess/NoAccess';
import { useDispatch, useSelector } from 'react-redux';
import { getUniqueStates } from 'redux-store/userManagementSetup/userManagementSetupActions';
import { listProviderAlerts } from 'redux-store/alerts/alertsActions';
import MenuItem from '../common/menuItem/MenuItem';
import procedureMgmtIconActive from '../../assets/imgs/procedureMgmt-icon-active.png';
import procedure from '../../assets/imgs/procedure.png';
import cardIconActive from '../../assets/imgs/card.png';
import cardInactive from '../../assets/imgs/cardInactive.png';
import handshakeIconActive from '../../assets/imgs/handshakeactive.png';
import familyIconActive from '../../assets/imgs/family.png';
import familyIconInActive from '../../assets/imgs/familyInactive.png';
import promoIconInactive from '../../assets/imgs/promo.png';
import promoIconActive from '../../assets/imgs/promoActive.png';
import handshakeIcon from '../../assets/imgs/handshake.png';
import medicalIcon from '../../assets/imgs/medical.png';
import medicalIconActive from '../../assets/imgs/medicalIconActive.png';
import specialityIcon from '../../assets/imgs/speciality.png';
import specialitySelected from '../../assets/imgs/specialitySelected.png';
import contentActive from '../../assets/imgs/contentActive.png';
import contentInactive from '../../assets/imgs/contentInactive.png';
import suggestionActive from '../../assets/imgs/send.png';
import suggestionInactive from '../../assets/imgs/sendinactive.png';
import programIconActive from '../../assets/imgs/progmsIconActive.png';
import programIconInactive from '../../assets/imgs/progmsIconInactive.png';
import messageIconActive from '../../assets/imgs/messageIconActive.png';
import messageIconInactive from '../../assets/imgs/messageIconInactive.png';
import homeScreenActive from '../../assets/imgs/homeScreenActive.png';
import homeScreenInactive from '../../assets/imgs/homeScreenInactive.png';
import InstitutionActive from '../../assets/imgs/institutionActive.png';
import InstitutionInactive from '../../assets/imgs/institutionInactive.png';
import GiveawayActive from '../../assets/imgs/giveawayActive.png';
import GiveawayInactive from '../../assets/imgs/giveawayInactive.png';
import ProviderActive from '../../assets/imgs/providerActive.png';
import ProviderInactive from '../../assets/imgs/providerInactive.png';
import RevGivesActive from '../../assets/imgs/revGivesActive.png';
import RevGivesInactive from '../../assets/imgs/revGivesInactive.png';
import './_dashboard.scss';

function Dashboard() {
  const access = useSelector((s) => s.auth?.profile?.accessFields);
  const myId = useSelector((state) => state?.auth?.user?.id);
  const { alerts } = useSelector((s) => s.alerts);
  const dispatch = useDispatch();

  useEffect(() => {
    if (myId) {
      dispatch(getUniqueStates({ limit: 0, page: 1 }));
      dispatch(listProviderAlerts({ 'where[isRead][equals]': false, limit: 0, depth: 0 }));
    }
  }, [myId]);

  if (!Object.values(access).find((v) => v)) {
    return (
      <NoAccess />
    );
  }
  return (
    <div className="dashBoardContainer">
      <NavTabs>
        <NavTabs.Menu className="navMenu">
          {access?.userManagement && (
            <NavTabs.MenuItems to={URLS.USERS_URL}>
              <MenuItem icon={familyIconInActive} activeIcon={familyIconActive} title="Users Mgmt." />
            </NavTabs.MenuItems>
          )}
          {(access?.procedureSetup || access?.suppliesSetup || access?.categorySetup || access?.labs || access?.biomarkerSetup || access?.medicationSetSetup || access?.quicklinksSetup) && (
            <NavTabs.MenuItems to={URLS.PROCEDURE_MGMT_URL}>
              <MenuItem icon={procedure} activeIcon={procedureMgmtIconActive} title="Procedure Mgmt." />
            </NavTabs.MenuItems>
          )}
          {access?.insurancePlans && (
            <NavTabs.MenuItems to={URLS.INSURANCE_PLAN_URL}>
              <MenuItem icon={cardInactive} activeIcon={cardIconActive} title="Insurance Plan" />
            </NavTabs.MenuItems>
          )}
          {access?.specialistSetup && (
            <NavTabs.MenuItems to={URLS.SPECIALTY_URL}>
              <MenuItem icon={specialityIcon} activeIcon={specialitySelected} title="Speciality" />
            </NavTabs.MenuItems>
          )}
          {access?.symptoms && (
            <NavTabs.MenuItems to={URLS.SYMPTOM_URL}>
              <MenuItem icon={medicalIcon} activeIcon={medicalIconActive} title="Symptoms" />
            </NavTabs.MenuItems>
          )}
          {(access?.profileSetup || access?.myProviders || access?.complaints) && (
            <NavTabs.MenuItems to={URLS.PRM_DASHBOARD_URL}>
              <MenuItem icon={handshakeIcon} activeIcon={handshakeIconActive} title="PRM" counter={alerts.docs?.filter((a) => a.isRead === false).length || ''} />
            </NavTabs.MenuItems>
          )}
          {(
            access?.providerFaq
            || access?.providerTerms
            || access?.providerHowTos
            || access?.memberFaq
            || access?.memberTerms
            || access?.memberHowTos
            || access?.privacyPolicy
            || access?.howToPrepare
            || access?.programCancelationPolicy
            || access?.memberInviteContent
          )
            && (
              <NavTabs.MenuItems
                to={URLS.CONTENT_DASHBOARD_URL}
              >
                <MenuItem icon={contentInactive} activeIcon={contentActive} title="Content" />
              </NavTabs.MenuItems>
            )}
          {(
            access?.memberFeedback
            || access?.providerFeedback
          )
            && (
              <NavTabs.MenuItems
                to={URLS.SUGGESTION_DASHBOARD_URL}
              >
                <MenuItem
                  icon={suggestionInactive}
                  activeIcon={suggestionActive}
                  title="Suggestions"
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center',
                  }}
                />
              </NavTabs.MenuItems>
            )}
          {access?.promotionSetup && (
            <NavTabs.MenuItems to={URLS.PROMOS_URL}>
              <MenuItem icon={promoIconInactive} activeIcon={promoIconActive} title="Promo Codes" />
            </NavTabs.MenuItems>
          )}
          {access?.programSetup && (
            <NavTabs.MenuItems to={URLS.PROGRAMS_URL}>
              <MenuItem icon={programIconInactive} activeIcon={programIconActive} title="Programs" />
            </NavTabs.MenuItems>
          )}
          {access?.memberHomeScreenSetup && (
            <NavTabs.MenuItems to={URLS.MEMBER_HOME_SCREEN_URL}>
              <MenuItem
                style={{ paddingLeft: 7, paddingRight: 7 }}
                icon={homeScreenInactive}
                activeIcon={homeScreenActive}
                title="Member
Home Screen"
              />
            </NavTabs.MenuItems>
          )}
          {access?.welcomeMessageSetup && (
            <NavTabs.MenuItems to={URLS.WELCOM_MESSAGE_URL}>
              <MenuItem icon={messageIconInactive} activeIcon={messageIconActive} title="Welcome Message" />
            </NavTabs.MenuItems>
          )}
          {access?.affiliationSetup && (
            <NavTabs.MenuItems to={URLS.AFFILIATIONS_URL}>
              <MenuItem icon={InstitutionInactive} activeIcon={InstitutionActive} title="Affiliations" />
            </NavTabs.MenuItems>
          )}
          {(access?.giveawaySetup || access?.giveawayCriteriaSetup) && (
            <NavTabs.MenuItems to={URLS.GIVEAWAYS_URL}>
              <MenuItem icon={GiveawayInactive} activeIcon={GiveawayActive} title="Giveaways" />
            </NavTabs.MenuItems>
          )}
          {access?.providerDefaultSetup && (
            <NavTabs.MenuItems to={URLS.PROVIDER_STATES_URL}>
              <MenuItem icon={ProviderInactive} activeIcon={ProviderActive} title="Provider" />
            </NavTabs.MenuItems>
          )}
          {access?.isRevGivesSetup && (
            <NavTabs.MenuItems to={URLS.REV_GIVES_URL}>
              <MenuItem icon={RevGivesInactive} activeIcon={RevGivesActive} title="RevGives" />
            </NavTabs.MenuItems>
          )}
        </NavTabs.Menu>
        <NavTabs.Content>
          <Outlet />
        </NavTabs.Content>
      </NavTabs>
    </div>
  );
}

export default Dashboard;
