import React from 'react';
import { useSelector } from 'react-redux';
import { Outlet } from 'react-router-dom';
import { URLS } from 'routes';
import NavTabs from 'shared/layouts/components/navTabs/NavTabs';
import './_homeTopDashboard.scss';

function MenuItem({ isActive, title, counter }) {
  return (
    <div className={`menuItem ${isActive ? 'active' : ''}`}>
      <span>{title}</span>
      {counter && <div className="counter">{`${counter >= 10 ? '9+' : counter}`}</div>}
    </div>
  );
}

function HomePageTopDashboard() {
  const access = useSelector((s) => s.auth?.profile?.accessFields);

  return (
    <div className="dashBoardWrapper">
      <NavTabs>
        <NavTabs.Menu className="subNavMenu">
          {access?.memberHomeTopSetup && (
            <NavTabs.MenuItems to={`${URLS.MEMBER_HOME1_SCREEN_URL}`}>
              <MenuItem title="RevAdults" />
            </NavTabs.MenuItems>
          )}
          {access?.memberHomeTopSetup && (
            <NavTabs.MenuItems to={`${URLS.MEMBER_HOME2_SCREEN_URL}`}>
              <MenuItem title="RevKids" />
            </NavTabs.MenuItems>
          )}
          {access?.memberHomeTopSetup && (
            <NavTabs.MenuItems to={`${URLS.MEMBER_HOME3_SCREEN_URL}`}>
              <MenuItem title="RevPets - Cat" />
            </NavTabs.MenuItems>
          )}
          {access?.memberHomeTopSetup && (
            <NavTabs.MenuItems to={`${URLS.MEMBER_HOME4_SCREEN_URL}`}>
              <MenuItem title="RevPets - Dog" />
            </NavTabs.MenuItems>
          )}
        </NavTabs.Menu>
        <NavTabs.Content>
          <Outlet />
        </NavTabs.Content>
      </NavTabs>
    </div>
  );
}

export default HomePageTopDashboard;
